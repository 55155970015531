import React, { Fragment, useContext } from 'react';
import { Highlight, Snippet } from 'react-instantsearch-dom';
import Link from 'next/link';
import { Box, Heading, Text, Button } from 'theme-ui';
import CartContext from '@/components/Contexts/CartContext';

export const ProizvodHit = ({ hit }) => {
  const { createLineItem } = useContext(CartContext);
  const addToCart = sku => createLineItem(sku, 1);
  return (
    <Box
      sx={{
        // borderBottom: "1px solid rgba(0,0,0,0.1)",
        display: 'flex',
        alignItems: 'center',
        py: 2,
        
      }}>
      <Box
        sx={{
          height: 160,
          flexBasis: 160,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexShrink: 0,
          mr: 5,
          border: '1px solid rgba(0,0,0,0.1)',
          borderRadius: 10,
        }}>
        <Link href={`/proizvod/${hit.slug}`}>
          <img
            style={{ maxHeight: '108px', maxWidth: '100%', width: 'auto' }}
            src={hit && hit.thumb}
          />
        </Link>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'start',
          justifyContent: 'center',
        }}>
        <Link href={`/proizvod/${hit.slug}`} passHref>
          <Box as="a">
            <Text sx={{ fontSize: 1, color: 'gray', mb: 2 }}>
              {hit && hit.robnaMarka && hit.robnaMarka.imeRobneMarke}
            </Text>
          </Box>
        </Link>
        <Link href={`/proizvod/${hit.slug}`} passHref>
          <Box as="a">
            <Text as="h3" sx={{ fontFamily: 'heading', mb: 2 }}>
              {hit.name}
            </Text>
          </Box>
        </Link>
        <Link href={`/proizvod/${hit.slug}`} passHref>
          <Box as="a">
            <Text
              sx={{
                fontSize: 4,
                pb: 4,
              }}
              as="p">
              {hit.cena} rsd
            </Text>
          </Box>
        </Link>
        <Box sx={{ display: 'flex' }}>
          {/* <Button mr={2} onClick={() => addToCart(hit.sku)} variant="small">
            Dodaj u korpu
          </Button> */}
          <Link href={`/proizvod/${hit.slug}`} passHref>
            <Box as="a">
              <Button
                variant="small"
                sx={{
                  border: '1px solid orange',
                  bg: 'white',
                  color: 'orange',
                  '&:hover': {
                    bg: 'orange',
                    color: 'white',
                  }
                }}>
                Pogledaj proizvod
              </Button>
            </Box>
          </Link>
        </Box>
      </Box>
    </Box>
  );
};

export const ProdavnicaHit = ({ hit }) => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      borderBottom: '1px solid rgba(0,0,0,0.1)',
      py: 4,
    }}>
    {/* {JSON.stringify(hit)} */}

    <Box
      sx={{
        height: 128,
        flexBasis: 128,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexShrinkg: 0,
        mr: 5,
      }}>
      <Link href={`/prodavnice/${hit.slug}`} passHref>
        <Box as="a">
          <img
            style={{ maxHeight: '128px', maxWidth: '100%', width: 'auto' }}
            src={hit && hit.slika1 && hit.slika1.url}
          />
        </Box>
      </Link>
    </Box>

    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        justifyContent: 'center',
      }}>
      <Text>{hit.opstina}</Text>
      <Link href={`/prodavnice/${hit.slug}`} passHref>
        <Box as="a">
          <Text pb={2} as="h3">
            <Highlight attribute="imeProdavnice" hit={hit} />
          </Text>
        </Box>
      </Link>

      {/* <Text>{hit.radnoVreme}</Text> */}

      <Box sx={{ display: 'flex' }}>
        <Link href={`/recepti/${hit.slug}`} passHref>
          <Box as="a">
            <Button variant="small">Pogledaj prodavnicu</Button>
          </Box>
        </Link>
      </Box>
    </Box>
  </Box>
);

export const RecepatHit = ({ hit }) => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      py: 4,
      borderBottom: '1px soldi rgba(0,0,0,0.1)',
    }}>
    <Box
      sx={{
        height: 128,
        flexBasis: 128,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexShrink: 0,
        mr: 5,
      }}>
      <Link href={`/recepti/${hit.url}`} passHref>
        <Box as="a">
          <img
            style={{ maxHeight: '128px', maxWidth: '100%', width: 'auto' }}
            src={hit && hit.thumb}
          />
        </Box>
      </Link>
    </Box>

    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        justifyContent: 'center',
      }}>
      <Link href={`/recepti/${hit.url}`} passHref>
        <Box as="a">
          <Text as="span">{hit.tezina}</Text>
        </Box>
      </Link>

      <Link href={`/recepti/${hit.url}`} passHref>
        <Box as="a">
          <Text pb={2} as="h3">
            <Highlight attribute="naslov" hit={hit} />
          </Text>
        </Box>
      </Link>

      <Box sx={{ display: 'flex' }}>
        <Link href={`/recepti/${hit.url}`} passHref>
          <Box as="a">
            <Button variant="small">Pogledaj recepat</Button>
          </Box>
        </Link>
      </Box>
    </Box>
  </Box>
);

export const SavetHit = ({ hit }) => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      py: 4,
      borderBottom: '1px solid rgba(0,0,0,0.1)',
    }}>
    <Box
      sx={{
        height: 128,
        flexBasis: 128,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexShrink: 0,
        mr: 5,
      }}>
      <Link href={`/saveti/${hit.urlSlug}`} passHref>
        <Box as="a">
          <img
            style={{ maxHeight: '128px', maxWidth: '100%', width: 'auto' }}
            src={hit && hit.thumb}
          />
        </Box>
      </Link>
    </Box>

    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        justifyContent: 'center',
      }}>
      <Link href={`/saveti/${hit.urlSlug}`} passHref>
        <Box as="a">
          <Text as="span">{hit.tezina}</Text>
        </Box>
      </Link>

      <Link href={`/saveti/${hit.urlSlug}`} passHref>
        <Box as="a">
          <Text pb={2} as="h3">
            <Highlight attribute="naslov" hit={hit} />
          </Text>
        </Box>
      </Link>

      <Box sx={{ display: 'flex' }}>
        <Link href={`/saveti/${hit.urlSlug}`} passHref>
          <Box as="a">
            <Button variant="small">Pogledaj savet</Button>
          </Box>
        </Link>
      </Box>
    </Box>
  </Box>
);
