import {useContext} from 'react';
import {Box, Text} from 'theme-ui';
import { useRouter } from 'next/router';
import AuthContext from '@/components/Contexts/AuthContext';
import IconWrapper from '@/components/IconWrapper';

const PrijaviSe = () => {
  // console.log("Prijavi Se RENDERED")
  const {isCustomerPanelOpen, setIsCustomerPanelOpen, user} = useContext(
    AuthContext
  );

  const router = useRouter()

  return (
    <Box
      onClick={() =>
        user && user.data && user.data.id
          ? router.push('/moj-nalog')
          : setIsCustomerPanelOpen(!isCustomerPanelOpen)
      }
      key="prijavise"
      sx={{
        listStyle: 'none',
        fontSize: [2, 2, 2, 2, 2, 3, 4],
        letterSpacing: '0.2px',
        color: 'text',
        cursor: 'pointer',
        '& .nav-item': {
          transition: 'all 0.25s ease-in-out',
        },
        '& .nav-item:hover': {
          color: 'darkGrey',
          transition: 'all 0.25s ease-in-out',
        },
      }}>
      <Text className="nav-item">
        {user && user.data && user.data.id ? (
          'Tvoj nalog'
        ) : (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}>
            {/* // One day if needed */}
            {/* <Box sx={{
            ml: 2,
            fontSize: 2,
            lineHeight: 1,
          }}>
            <Text>Nalog</Text>
          </Box> */}
            <IconWrapper icon="user" />
            {/* <Box sx={{
            width: 42,
            height: 42,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderStyle: 'solid',
            borderWidth: '1px',
            borderColor: 'altGreen',
            borderRadius: '10px',
          }}>
            <svg width="24" height="24" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g opacity="0.7">
                <path d="M9.99984 9.79167C12.5311 9.79167 14.5832 7.73964 14.5832 5.20833C14.5832 2.67703 12.5311 0.625 9.99984 0.625C7.46853 0.625 5.4165 2.67703 5.4165 5.20833C5.4165 7.73964 7.46853 9.79167 9.99984 9.79167Z" fill="#174949" />
                <path d="M9.99992 11.041C5.62976 11.0461 2.0883 14.5875 2.08325 18.9577C2.08325 19.1878 2.2698 19.3743 2.49992 19.3743H17.4999C17.73 19.3743 17.9166 19.1878 17.9166 18.9577C17.9115 14.5875 14.3701 11.0461 9.99992 11.041Z" fill="#174949" />
              </g>
            </svg>
          </Box> */}
          </Box>
        )}
      </Text>
    </Box>
  );
};

export default PrijaviSe;
