import React from 'react';

const AuthContext = React.createContext({
  token: '',
  refreshToken: '',
  user: '',
  hasErrors: null,
  updateToken: () => {},
  updateRefreshToken: () => {},
  updateTokenExpires: () => {},
  updateUser: () => {},
  setToken: () => {},
  tokenExpires: '',
  setIsCustomerPanelOpen: () => {},
  registerCustomer: () => {},
  loginCustomer: () => {},
  createCustomerPasswordReset: () => {},
  logoutCustomer: () => {},
  updateCustomerPasswordReset: () => {},
  klaviyoTrack: () => {},
  addCustomerPassword: () => {},
  addedPassword: false,
  setAddedPassword: () => {},
  toggleCustomerPanel: () => {},
});

export default AuthContext;
