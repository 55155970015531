import {useContext, useState} from 'react';
import {Box, Text, Button} from 'theme-ui';
// import {Link as GatsbyLink, navigate} from 'gatsby';
import Link from "next/link"
import {useRouter} from "next/router"
import styled from '@emotion/styled';
import CartContext from '@/components/Contexts/CartContext';
import AuthContext from '@/components/Contexts/AuthContext';
import IconWrapper from '@/components/IconWrapper';

const MobileBottomMenu = props => {
  const {setCartOpen, cartOpen} = useContext(CartContext);
  const {isCustomerPanelOpen, setIsCustomerPanelOpen, user} = useContext(
    AuthContext
  );
  const LinkFooterIcon = styled(Link)`
    display: flex;
    flex-direction: column;
    align-items: center;
    color: none;
    text-decoration: none;
  `;
  const [localCartOpen, setLocalCartOpen] = useState(cartOpen);
  const [localLoginOpen, setLocalLoginOpen] = useState(isCustomerPanelOpen);

  const {pageContext, order, scrollingDown, setSearchOpen} = props;
    const router = useRouter()
  const handleAccountLabel = () =>
    user && user.data && user.data.id ? 'Nalog' : 'Prijavi se';

  const handlePageTypeComponent = pageContext => (
    <Box
      sx={{
        display: ['flex', 'none'],
        position: 'fixed',
        bottom: '44px',
        left: '5vw',
        zIndex: '10',
        width: '90vw',
        height: '54px',
        fontFamily: 'body',
        bg: 'primaryGreenHighlight',
        zIndex: '100',
        boxShadow: '0 1px 3px 0',
        boxSizing: 'border-box',
        alignItems: 'center',
        justifyContent: 'space-around',
        borderRadius: '8px',
        pb: '1px',
        // color: 'white',
        transformOrigin: 'center bottom',
        transform: scrollingDown ? 'scale(0)' : 'scale(1)',
        transition: 'transform 0.25s ease-in-out',
        '& svg *': {
          stroke: 'white',
          fill: 'white',
        },
      }}>
      <Box key={1}>
        <LinkFooterIcon passHref href="/categories">
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            color: 'none',
            textDecoration: 'none',
          }} as="a">
          {/* <CategoryIcon /> */}
          <IconWrapper icon="categoryList" noBorder />
          <Text
            sx={{
              letterSpacing:"0.1px",
              marginBottom: -1,
              fontFamily: "body",
              fontSize: 1 ,
              lineHeight: 1.75,
              color:"white"
            }}>
            Kategorije
          </Text>
          </Box>
        </LinkFooterIcon>
      </Box>

      <Box key={2} onClick={() => setSearchOpen(true)}>
        <LinkFooterIcon passHref href="/">
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            color: 'none',
            textDecoration: 'none',
          }} as="a">
          {/* <HomeIcon /> */}
          <IconWrapper icon="search" noBorder />
          <Text
           sx={{
            letterSpacing:"0.1px",
            marginBottom: -1,
            fontFamily: "body",
            fontSize: 1 ,
            lineHeight: 1.75,
            color:"white"
          }}
            >
            Pretraži
          </Text>
          </Box>
        </LinkFooterIcon>
      </Box>

      <Box key={3}>
        <Box
          sx={{
            letterSpacing:"0.1px",
            marginBottom: -1,
            fontFamily: "body",
            fontSize: 1 ,
            lineHeight: 1.75,
            color:"white"
          }}
          onClick={() => router.push('/korpa')}>
          <Box
            // onClick={() => {
            //   setCartOpen(true);
            //   setLocalCartOpen(true);
            // }}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              color: 'none',
              textDecoration: 'none',
              position: 'relative',
            }}>
            <Box
              sx={{
                position: 'absolute',
                left: '50%',
                transform: 'translateX(-50%)',
                top: '25%',
                borderRadius: '50%',
                bg: 'primaryOrange',
                zIndex: 2,
                width: '16px',
                height: '16px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <Text
                sx={{
                  color: 'white',
                  fontSize: '10px',
                  paddingRight: '2px',
                  textAlign: 'center',
                  width: '100%',
                  mb: '1px',
                }}>
                {/* {(order && order.data.attributes.skus_count) || 0} */}
                {(order &&
                      order.data.relationships.line_items.data.length) ||
                      0}
              </Text>
            </Box>
            {/* <CartIcon /> */}
            <IconWrapper icon="basket" noBorder />
            <Text
              sx={{
                letterSpacing:"0.1px",
                marginBottom: -1,
                fontFamily: "body",
                fontSize: 1 ,
                lineHeight: 1.75,
                color:"white"
              }}>
              Korpa
            </Text>
          </Box>
        </Box>
      </Box>

      <Box
        key={4}
        onClick={() => {
          user && user.data && user.data.id ? router.push('/moj-nalog') : setIsCustomerPanelOpen(true), setLocalLoginOpen(true)
        }}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          color: 'white',
          textDecoration: 'none',
        }}>
        <IconWrapper icon="user" noBorder />
        <Text
         sx={{
          letterSpacing:"0.1px",
          marginBottom: -1,
          fontFamily: "body",
          fontSize: 1 ,
          lineHeight: 1.75,
          color:"white"
        }}>
          {handleAccountLabel()}
        </Text>
      </Box>
    </Box>
  );

  return handlePageTypeComponent(pageContext);
};

export default MobileBottomMenu;
