import Link from 'next/link';
import dynamic from 'next/dynamic';
import { Flex, Box, Text } from 'theme-ui';
import { useContext, useState, useEffect } from 'react';
import { useTransition  } from 'react-spring';
import MobileBottomMenu from '../MobileBottomMenu';
// const MobileBottomMenu = dynamic(() => ('../MobileBottomMenu'))
import IconWrapper from '@/components/IconWrapper';
// const IconWrapper = dynamic(() => ('@/components/IconWrapper'))
import LogoBioSpajz from '@/components/logoBiospajz';
// const LogoBioSpajz = dynamic(() => ('@/components/logoBiospajz'))
import { toggleBodyScroll } from '../Hooks/toggleBodyScroll';
import CartContext from '@/components/Contexts/CartContext';
import PrijaviSe from './PrijaviSe';
// const PrijaviSe = dynamic(() => ('./PrijaviSe'))
import RegisterLogin from '@/components/RegisterLogin';
// const RegisterLogin = dynamic(() => ('@/components/RegisterLogin'))
import Search from '../Search';
// const Search = dynamic(() => ('../Search'))

import MobilePageSideBar from '../MobilePageSideBar';
// const MobilePageSideBar = dynamic(() => ('../MobilePageSideBar'))

import { useRouter } from 'next/router';

const Header = ({
  pageContext,
  // location
}) => {
  const { order, setSearchOpen, searchOpen } = useContext(CartContext);
  const [scrollingDown, setScrollingDown] = useState(false);

  const router = useRouter()
  const pathname = router.asPath

  useEffect(() => {
    const threshold = 20;
    let lastScrollY;
    if (typeof window !== 'undefined') {
      lastScrollY = window.pageYOffset;
    }
    let ticking = false;

    const updateScrollDir = () => {
      let scrollY;
      if (typeof window !== 'undefined') {
        scrollY = window.pageYOffset;
      }
      if (Math.abs(scrollY - lastScrollY) < threshold) {
        ticking = false;
        return;
      }
      setScrollingDown(scrollY > lastScrollY);
      lastScrollY = scrollY > 0 ? scrollY : 0;
      ticking = false;
    };

    const onScroll = () => {
      if (!ticking) {
        if (typeof window !== 'undefined') {
          window.requestAnimationFrame(updateScrollDir);
        }
        ticking = true;
      }
    };

    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, [scrollingDown]);

  const [isSideBarOpen, setSideBarOpen] = useState(false);

  const sideMenu = useTransition(isSideBarOpen, {
    from: {
      position: 'fixed',
      opacity: 0,
      transform: 'translate3d(-250px,0,0)',
    },
    enter: {
      opacity: 1,
      transform: 'translate3d(0,0,0)',
    },
    leave: {
      opacity: 0,
      transform: 'translate3d(-250px,0,0)',
    },
  });

  const leftNav = [
    {
      title: 'Proizvodi',
      link: '/categories',
    },
    {
      title: 'Saveti',
      link: '/saveti',
    },
    {
      title: 'Recepti',
      link: '/recepti',
    },
    {
      title: 'Prodavnice',
      link: '/prodavnice',
    },
    // {
    //   title: 'Veleprodaja',
    //   link: '/veleprodaja',
    // },
    {
      title: 'Naša priča',
      link: '/o-nama',
    },
    {
      title: 'Kontakt',
      link: '/kontakt',
    },
  ];

  return (
    <Box
      as="header"
      sx={{
        display: 'flex',
        flexDirection: ['column', 'row', 'row', 'column'],
        zIndex: 300,
        top: 0,
        left: 0,
        width: '100%',
        position: ['relative'],
        background: 'white',
        mt: [0, 3, 3, 0],
        px: [1, 3, 6],
      }}>
      <Box
        sx={{
          pt: [0, 0, 0, 2],
          mb: [2, 2, 3],
          display: ['flex', 'flex'],
          justifyContent: 'space-between',
          height: [searchOpen ? '100%' : 64, 'auto'],
          width: ['auto', 'auto', 'auto', '100%'],
        }}>
        <Flex
          sx={{
            justifyContent: ['center', 'center', 'center', 'space-between'],
            flexDirection: [
              'row-reverse',
              'row-reverse',
              'row-reverse',
              'row',
              'row',
              'row',
            ],
            width: '100%',
            position: 'relative',
          }}>
          <Link href="/" passHref>
            <a>
              <Box
                className="SVGHOLDER"
                sx={{
                  display: ['flex', 'none', 'none', 'flex'],
                  pt: 2,
                  cursor: 'pointer',
                  width: 140,
                  height: [searchOpen ? '100%' : 64, 'auto'],
                }}>
                <LogoBioSpajz />
              </Box>
            </a>
          </Link>
          <Box
            onClick={() => setSideBarOpen(true)}
            sx={{
              display: ['flex', 'flex', 'flex', 'none'],
              flexDirection: 'column',
              justifyContent: 'center',
              pt: ['14px', '14px', 0, 2],
              position: [
                'absolute',
                'relative',
                'relative',
                'relative',
                'relative',
                'relative',
              ],
              left: [2, 'auto'],
              top: [0, 'auto'],
              mr: [2, 2, 0],
            }}>
            <IconWrapper icon="menu" />
          </Box>

          <Box
            as="ul"
            sx={{
              pl: 4,
              display: ['none', 'none', 'none', 'flex'],
              alignItems: 'center',
              fontFamily: 'body',
              fontWeight: '900',
              '& a':{
                color: 'inherit',
                textDecoration: 'none',
              }
            }}>
            {leftNav.map(x => (
              <Box
                as="li"
                key={x.link}
                sx={{
                  mt: 3,
                  listStyle: 'none',
                  px: [0, 0, 1, 2, 3],
                  fontSize: [2, 2, 2, 2, 2, '14px', 4],
                  letterSpacing: '0.5px',
                  color: 'text',
                  cursor: 'pointer',
                  '& .nav-item': {
                    transition: 'all 0.25s ease-in-out',
                  },
                  '& .nav-item:hover': {
                    color: 'darkGrey',
                    transition: 'all 0.25s ease-in-out',
                  },
                }}>
                <Link href={x.link} passHref>
                  <Box as="a" onClick={() => setSideBarOpen(false)}>
                    <Text className="nav-item">{x.title}</Text>
                  </Box>
                </Link>
              </Box>
            ))}
          </Box>


          <Flex
            sx={{
              flexShrink: 0,
              alignItems: 'flex-end',
            }}>
            <Box
              // pl={4}
              sx={{
                display: ['none', 'none', 'none', 'flex'],
                // alignItems: 'center',
                fontFamily: 'body',
                fontWeight: '900',
              }}>
              <PrijaviSe />
            </Box>
            <Box
              sx={{
                textDecoration: 'none',
                color: 'inherit',
                '&:hover': {
                  cursor: 'pointer',
                },
              }}
            >
              <Box
                role="link"
                onClick={() => {
                  setSideBarOpen(false);
                  router.push('/korpa');
                }}
                sx={{
                  display: ['none', 'none', 'none', 'flex'],
                  borderRadius: '50%',
                  cursor: 'pointer',
                  position: 'relative',
                  ml: 2,
                }}>
                <Box
                  as="span"
                  sx={{
                    position: 'absolute',
                    left: '50%',
                    bottom: '10px',
                    transform: 'translate(-50%)',
                    fontWeight: '900',
                    zIndex: '1',
                    color: 'white',
                    fontFamily: 'body',
                    fontSize: '2',
                  }}>
                  {(order &&
                    order.data.relationships.line_items.data.length) ||
                    0}
                </Box>
                <IconWrapper icon="basket" />
              </Box>
            </Box>
          </Flex>
  

        </Flex>
      </Box>

      <Box
        id="search-wrap"
        sx={{
          display: [searchOpen ? 'flex' : 'block', 'block'],
          position: searchOpen ? 'fixed' : 'relative',
          justifyContent: 'center',
          alignItems: 'center',
          width: searchOpen ? '100%' : 'auto',
          height: [searchOpen ? '100%' : 'auto'],
          px: searchOpen ? 0 : 2,
          bg: 'white',
          zIndex: searchOpen ? 5000 : 'auto',
          left: 0,
          pl: 0,
          pr: 0,
        }}>
        <Search pathname={pathname} collapse />
        <Box
          onClick={() => {
            setSearchOpen(false);
            toggleBodyScroll(!searchOpen);
            
          }}
          sx={{
            display: [searchOpen ? 'flex' : 'none', 'block'],
            position: 'absolute',
            bottom: 32,
            left: '50%',
            transform: 'translateX(-50%)',
          }}>
          {searchOpen && <IconWrapper icon="close" />}
        </Box>
      </Box>


      {sideMenu((styles,item) =>
        item ? (
          <MobilePageSideBar
            styleX={styles}
            isOpen={isSideBarOpen}
            closeSideBar={() => setSideBarOpen(false)}
          />
        ) : null
      )}
      {/* <CartPopup allProducts={allProducts} pageType={pageContext.type} /> */}
      <RegisterLogin />
      <Box
        sx={{
          display: ['block', 'block', 'none'],
        }}>
        { !router.pathname.includes('checkout') &&
          !router.pathname.includes('korpa') && (
            <MobileBottomMenu
              order={order}
              pageContext={pageContext}
              scrollingDown={scrollingDown}
              setSearchOpen={boolean => {
                toggleBodyScroll(!searchOpen);
                setSearchOpen(boolean);
              }}
            />
          )}
      </Box>
    </Box>
  );
};

export default Header;
