export const handleTax = (order) => {
    // console.log('order');
    if (order) {
      const skus = order && order.included && order.included.filter(x => x.type === 'skus');
      const lineItems = order && order.included && order.included.filter(
        x => x.type === 'line_items' && x.attributes.item_type === 'skus'
      );
      let taxAmount;
      if (lineItems && lineItems.length > 0)
        taxAmount = lineItems.reduce((total, currentLineItem) => {
          // console.log('skus', skus);
          const currentSku = skus.filter(
            x => x.attributes.code === currentLineItem.attributes.sku_code
          );
          const currentSkuTaxCategory =
            currentSku[0].attributes.metadata.taxCategory;
          const currentLineItemTaxAmount =
            currentLineItem.attributes.total_amount_cents *
            (currentSkuTaxCategory / 100);

          // console.log('currentSKu', currentLineItem.attributes.total_amount_cents,currentLineItemTaxAmount, currentSkuTaxCategory );
          return total + currentLineItemTaxAmount;
        }, 0);
      // return total + currentSkuTaxAmount},0)
      return taxAmount / 100;
    }
    return null;
  };