import React, {useContext} from 'react';
import {Box, Heading, Button, Text, Input} from 'theme-ui';

import {useForm} from 'react-hook-form';

import AuthContext from '@/components/Contexts/AuthContext';

const WarningLove = ({children}) => (
  <Text
    as="p"
    sx={{
      fontSize: 1,
      position: 'absolute',
      left: 3,
      bottom: [2, 2, 2, '%px'],
      color: 'red',
      letterSpacing: '0.1px',
      fontFamily: 'body',
    }}>
    {children}
  </Text>
);

const BoxLove = ({children}) => (
  <Box
    sx={{
      position: 'relative',
      pb: [1, 1, 1, 4],
      width: '100%',
      '& input::placeholder': {
        color: 'lightGray',
      },
    }}>
    {children}
  </Box>
);

const ForgotPasswordForm = ({
  toggleForgotPassword,
  isLogin,
  toggleRegisterLogin,
}) => {
  const {hasErrors, createCustomerPasswordReset} = useContext(AuthContext);

  const {handleSubmit, register, errors} = useForm({
    mode: 'onBlur',
  });

  const onSubmit = values => {
    createCustomerPasswordReset(values);
  };

  return <>
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '300px',
        }}>
        {/* <Box bg="#eee" width="200px" height="200px" mb="3"></Box> */}
        <Heading
          sx={{
            fontSize: 6,
            color: 'white',
            mb: 3,
          }}>
          Poslaćemo ti email za novu lozinku
        </Heading>
        <BoxLove>
          <Input
            placeholder="Tvoj email"
            id="email"
            {...register('email', {
              required: 'Email je.. ipak potreban :)',
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: 'Mmm, izgleda da adresa nije dobro upisana...',
              },
            })}
            type="text"
            sx={{
              color: 'white',
            }} />
          <WarningLove>
            {errors?.email ? errors?.email.message : null}
          </WarningLove>
        </BoxLove>
        {hasErrors && hasErrors == "noAccount" && 
      <Text as="p" sx={{color: 'red', fontSize: 4, textAlign: 'center', mb: 3}}>
        Nalog ne postoji sa ovom adresom.
      </Text>
      }
      {hasErrors && hasErrors == "guestAccount" && 
      <Text as="p" sx={{color: 'red', fontSize: 4, textAlign: 'center', mb: 3}}>
        Nalog je već korišćen. Kontaktirajte na internetprodavnica@biospajz.rs.
      </Text>
      }
        <Button
          type="submit"
          sx={{
            fontSize: 3,
            px: 4,
            width: '100%',
            mb: 3,
          }}>
          Potvrdi reset lozinku
        </Button>
        <Heading
          onClick={toggleForgotPassword}
          sx={{
            mt: 2,
            fontSize: 4,
            color: 'white',
            textAlign: 'center',
            width: 200,
            '&:hover': {
              cursor: 'pointer',
              opacity: 0.75,
            },
          }}>
          Ipak ne treba? Vrati se nazad
        </Heading>
      </Box>
    </form>
  </>;
};

export default ForgotPasswordForm;
