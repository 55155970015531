import {useRef, useEffect} from 'react';

const useOutsideAlerter = (ref, togglerFunction, isActive) => {
  const handleClickOutside = event => {
    if (ref.current && !ref.current.contains(event.target)) {
      togglerFunction();
    }
  };

  useEffect(() => {
    // Bind the event listener
    isActive && document.addEventListener('mousedown', handleClickOutside);
    // console.log('MOUNTED')
    return () => {
      // Unbind the event listener on clean up
      // console.log('DE-MOUNTED')
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });
};

const OutsideAlerter = ({children, togglerFunction, isActive}) => {
  // console.log(`okida ${togglerFunction}`)
  // console.log('ALert Outside Wrapper renderered', isActive)
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, togglerFunction, isActive);

  return (
    <div className="AlertOutsideWrapper" ref={wrapperRef}>
      {children}
    </div>
  );
};

export default OutsideAlerter;
