export default {
    breakpoints: ['40em', '52em', '64em', '80em', '85em'],
    colors: {
      text: '#333',
      background: '#fff',
      primary: '#639',
      secondary: '#ff6347',
      white: '#fff',
      primaryGreen: '#143131',
      primaryGreenLight: '#295757',
      primaryGreenHighlight: '#4a7d7d',
      primaryOrange: '#F2994A',
      lightGray: '#F0F0F0',
      darkGray: '#969696',
      darkGreen: '#032D1D',
      altGreen: '#658c0d',
      green: '#003615',
      orange: '#ffa500',
    },
    fonts: {
      body: 'Noto Sans, sans-serif',
      heading: 'Recoleta, serif',
      monospace: 'Menlo, monospace',
      p: 'HK Grotesk, sans-serif',
    },
    shadows: {
      xs: '0 0 0 1px rgba(0, 0, 0, 0.05)',
      sm: '0 1px 2px 0 rgba(0, 0, 0, 0.05)',
      default: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
      md: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
      lg:
        '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
      xl:
        '0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)',
      xxl: '0 25px 50px -12px rgba(0, 0, 0, 0.25)',
      inner: 'inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)',
      outline: '0 0 0 3px rgba(66, 153, 225, 0.5)',
      none: 'none',
    },
    styles: {
      root: {
        fontFamily: 'body',
        fontWeight: 300,
      },
      h1: {
        fontSize: [30, 40, 62],
        fontFamily: 'heading',
      },
      h3: {
        fontFamily: 'heading',
      },
      p: {
        fontFamily: 'body',
        fontWeight: 300,
      },
      input: {},
    },
    fontWeights: {
      body: 400,
      heading: 500,
      bold: 700,
    },
    lineHeights: {
      body: 1.5,
      heading: 1,
    },
    fontSizes: [10, 12, 14, 16, 20, 24, 32, 48, 64, 72],
    space: [0, 4, 8, 16, 24, 32, 64, 128, 256, 512],
    text: {
      heading: {
        fontFamily: 'heading',
        fontWeight: 'heading',
        lineHeight: 'heading',
      },
      color: '#555',
      fontFamily: 'body',
    },
    variants: {
      bsRoundButtonHovered: {
        bg: 'green',
        padding: '5rem',
      },
      none: {},
      nostyle: {
        bg: 'green',
      },
    },
    forms: {
      input: {
        border: '1px solid rgba(255,255,255,0.35)',
        // bg: '#eee',
        mb: '3',
        fontSize: '3',
        fontWeight: 'bold',
        borderRadius: '0.35rem',
        pl: '3',
        letterSpacing: '0.5px',
        fontFamily: 'body',
      },
      select: {
        border: '2px solid #eee',
        bg: '#eee',
        mb: '3',
        fontSize: '3',
        fontWeight: 'bold',
        borderRadius: '0.35rem',
        pl: '3',
        letterSpacing: '0.5px',
        fontFamily: 'body',
      },
      textarea: {
        border: '2px solid #eee',
        bg: '#eee',
        mb: '3',
        fontSize: '3',
        fontWeight: 'bold',
        borderRadius: '0.35rem',
        pl: '3',
        letterSpacing: '0.5px',
        fontFamily: 'body',
      },
      radio: {
        fontSize: 7,
      },
    },
    buttons: {
      primary: {
        bg: 'primaryOrange',
        px: '7',
        py: '5',
        fontSize: '5',
        fontWeight: '500',
        textTransform: 'uppercase',
        fontFamily: 'heading',
        letterSpacing: '1.5px',
        cursor: 'pointer',
        transition: 'all 0.25s ease-in-out',
        '&:disabled': {
          opacity: 0.2,
          cursor: 'default',
          '&:hover': {
            bg: 'primaryOrange',
          },
        },
        '&:hover': {
          bg: 'primaryGreenHighlight',
        },
      },
      secondary: {
        bg: 'primaryOrange',
        fontFamily: 'heading',
        px: 5,
        py: 3,
        fontWeight: 600,
        letterSpacing: 1,
        cursor: 'pointer',
        textTransform: 'Capitalize',
        '&:hover': {
          opacity: 0.8,
        },
        '&:disabled': {
          opacity: 0.2,
          cursor: 'default',
          '&:hover': {
            bg: 'primaryOrange',
          },
        },
      },
      nostyle: {
        bg: 'none',
        fontSize: 100,
      },
      small: {
        cursor: 'pointer',
        fontSize: '2',
        fontFamily: 'heading',
        bg: 'orange',
        width: 'auto',
      },
    },
  };
  