// When the App starts, it gets an access token, via Gatsby-config / Auth Provider
// Then if refreshtoken exists it tries to get a new JWT token for the log-in of the user.

// When the user logs-in, we save his refresh token to localStorage.
// If there is refreshtoken / or the person is logging in, the user is set to state, and localstorage.

// Deleting localstorage items is currently ONLY when user logs-out

// *** *** Klaviyo -> via digitalOcean -> klaviyo
// Identify upon every log-in
// Send tracking when visiting Product
// Send Add to cart event to Klaviyo
// Send Order placed event to Klaviyo

import {useState, useEffect} from 'react';
import { useRouter } from 'next/router';
import axios from 'axios';
import AuthContext from './AuthContext';

const AuthProvider = ({children}) => {
  const [token, setToken] = useState(null);
  const [refreshToken, setRefreshToken] = useState(null);
  const [tokenExpires, setTokenExpires] = useState(null);
  const [user, setUser] = useState(null);
  const [isCustomerPanelOpen, setIsCustomerPanelOpen] = useState(false);
  const [hasErrors, setHasErrors] = useState(null);
  const [authOrderId, setAuthOrderId] = useState(null);
  const [addedPassword, setAddedPassword] = useState(false)

  function isLocalStorageAvailable() {
    try {
        var test = '__test__';
        localStorage.setItem(test, test);
        localStorage.removeItem(test);
        return true;
    } catch (e) {
        return false;
    }
  }

  const router = useRouter()

  const updateToken = token => setToken(token);

  const updateRefreshToken = refreshToken => {
    setRefreshToken(refreshToken);
    if(isLocalStorageAvailable()) {
      localStorage.setItem('refreshToken', refreshToken);
    }
  };
  const updateTokenExpires = tokenExpires => setTokenExpires(tokenExpires);

  const updateUser = (userId, token) => {
    // axios({
    //   method: 'GET',
    //   url: `${process.env.COMMERCE_LAYER_API}/api/customers/${userId}?include=orders,customer_addresses.address,customer_subscriptions`,
    //   headers: {
    //     Accept: 'application/vnd.api+json',
    //     Authorization: `Bearer ${token}`,
    //   },
    // })
    axios
      .get(`/api/fetchUser`, {
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
        },
        params: {
          customerId: userId,
          token: token,
        }
      })
      .then(res => {
        // console.log('res userData', res.data);
        setUser(res.data);
        if(isLocalStorageAvailable()) {
          localStorage.setItem('user', JSON.stringify(res.data));
        }
        //Identify with Klaviyo
        // axios({
        //   method: 'GET',
        //   url: `/api/klaviyoIdentify?user=${res.data.data.attributes.email}`,
        //   headers: {
        //     accept: 'application/json',
        //     'content-type': 'application/json',
        //   },
        // })
        // check if any pending orders before, if none create, if none
        const usersOrders = res.data.included;
        const filterPending =
          res.data.included &&
          res.data.included.filter(x => x.attributes.status === 'pending');
        if (filterPending && filterPending.length > 0) {
          const lastPendingOrder = filterPending[0];
          setAuthOrderId(lastPendingOrder.id);
        }
        if ((filterPending && filterPending.length < 1) || !filterPending) {
          let localStorageOrderId
          if(isLocalStorageAvailable()) {
            localStorageOrderId = localStorage.getItem("orderId");
          } 
          if (localStorageOrderId) claimOrder(localStorageOrderId, token);
        }
      })
      .catch(e => {
        console.log('+5552', e);
      });
  };

  //   TODO: Set a trigger function when the Token Expires

  // const signOut = () => {
  //   setToken('')
  // }

  const getToken = () => {
    axios
      .get(`/api/getToken`, {
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
        },
      })
      .then(data => {
        updateToken(data.data.access_token);
        updateTokenExpires(data.data.expires_in);
      })
      .catch(e => {
        console.log(e);
      });
  };

  const getRefreshToken = localRefreshToken => {
    axios({
      method: 'POST',
      url: `/api/refreshToken?refreshToken=${localRefreshToken}`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then(res => {
        updateToken(res.data.access_token);
        updateRefreshToken(res.data.refresh_token);
        updateUser(res.data.owner_id, res.data.access_token);
      })
      .catch(e => {
        getToken();
      });
  };

  const getChannelAccessToken = async () => {
    let localRefreshToken
    if(isLocalStorageAvailable()) {
      localRefreshToken = localStorage.getItem('refreshToken');
    }
    if (localRefreshToken && localRefreshToken !== "undefined") {
      getRefreshToken(localRefreshToken);
    } else {
      getToken();
    }
  };

  const getPasswordToken = async ({email, password}) => {
    let fetchedData = null;
    await axios
      .post(
        `/api/getPasswordToken?username=${email}&password=${password}`,
        {
          headers: {
            accept: 'application/json',
            'content-type': 'application/json',
          },
        }
      )
      .then(data => {
        updateUser(data.data.owner_id, data.data.access_token);
        updateToken(data.data.access_token);
        updateTokenExpires(data.data.expires_in);
        data.data.refresh_token === 'undefined'
          ? null
          : updateRefreshToken(data.data.refresh_token);
        fetchedData = data.data;
      })
      .catch(e => {
        console.log(e);
      });
    return fetchedData;
  };

  const getIntegrationToken = async () => {
    let fetchedToken = null;
    await axios({
      method: 'GET',
      url: `/api/getIntegrationToken`,
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
      },
      params: {
        token,
      },
    })
      .then(data => {
        // updateUser(data.data.owner_id, data.data.access_token)
        // updateToken(data.data.access_token)
        // updateTokenExpires(data.data.expires_in)
        // data.data.refresh_token === "undefined" ? null : updateRefreshToken(data.data.refresh_token)
        // fetchedData = data.data
        fetchedToken = data.data.access_token;
      })
      .catch(e => {
        console.log(e);
      });
    return fetchedToken;
  };

  const clearTokens = () => {};

  const registerCustomer = async values => {
    await axios
      .get(`/api/registerCustomer`, {
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
        },
        params: {
          token,
          email: values.email,
          password: values.password,
        },
      })
      .then(res => {
        if(res.data.message == "email is takene") {
          setHasErrors("Adresa je već iskorišćena")
        } else {
        klaviyoTrack(values.email, "Customer Registered")
        getPasswordToken(values);
        setIsCustomerPanelOpen(false);
        // setUser(res.data.data)
        // localStorage.setItem('user', JSON.stringify(res.data.data))
        setHasErrors(null);
        typeof window !== "undefined" && window && window.gtag && window.gtag('event', 'sign_up', {
          method: 'CommerceLayer'
        });
        }
      })
      .catch(e => {
        setHasErrors(e);
      });
  };

  const addCustomerPassword = async values => {
    await axios
      .get(`/api/updateCustomerPassword`, {
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
        },
        params: {
          token,
          customerId: values.customerId,
          password: values.password,
        },
      })
      .then(res => {
        setAddedPassword(true);
        setHasErrors(null);
      })
      .catch(e => {
        setHasErrors(e);
      });
  };

  const loginCustomer = async values => {
    try {
      const {owner_id, access_token} = await getPasswordToken(values);
      setIsCustomerPanelOpen(false);
      setHasErrors(null);
      typeof window !== "undefined" && window && window.gtag && window.gtag('event', 'login', {
        method: 'CommerceLayer'
      });
    } catch (error) {
      setHasErrors(error);
    }
  };

  const logoutCustomer = async values => {
    getChannelAccessToken();
  };

  // const fetchUserLastOrder = () => {
  //   const result = null;
  //   axios
  //     .get(`/api/fetchUserLastOrder`, {
  //       headers: {
  //         accept: 'application/json',
  //         'content-type': 'application/json',
  //       },
  //       params: {
  //         customerId,
  //         token,
  //       },
  //     })
  //     .then(res => {});
  // };

  const claimOrder = (localStorageOrderId, token) => {
    axios.get(`/api/claimOrder`, {
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
      },
      params: {
        localStorageOrderId,
        token,
      },
    });
  };

  const toggleCustomerPanel = () => {
    setIsCustomerPanelOpen(!isCustomerPanelOpen);
  };

  const createCustomerPasswordReset = async values => {
    let newToken = null;
    newToken = await getIntegrationToken();
    axios
      .get(
        `/api/createCustomerPasswordReset`,
        {
          headers: {
            accept: 'application/json',
            'content-type': 'application/json',
          },
          params: {
            token: newToken,
            values,
          },
        }
      )
      .then(res => {
        // console.log('res', res)
        if(res.data.message == "noPassword") {
          setHasErrors("guestAccount")
        } else if(res.data.message == "must exist") {
          setHasErrors("noAccount")
        } else {
          setHassErrors(null)
        }
        // klaviyoTrack(res.data.data.attributes.customer_email, "Customer Reset Password", res.data.data.attributes.reset_password_token)
        // TO DO: Send email with token link and create page for writing a new password
      })
      .catch(error => {});
  };

  const updateCustomerPasswordReset = async (values, resetToken, resetId) => {
    let newToken = null;
    newToken = await getIntegrationToken();
    axios
      .get(
        `/api/updateCustomerPasswordReset`,
        {
          headers: {
            accept: 'application/json',
            'content-type': 'application/json',
          },
          params: {
            token: token,
            resetToken: resetToken,
            resetId: resetId,
            values,
          },
        }
      )
      .then(res => {
        // console.log('res',res)
        if(res.data.name === "Error") {
          setHasErrors("newPasswordFail")
        } else {
          router.push('/')
          toggleCustomerPanel()
        }
        
      })
      .catch(error => {});
  };



  const klaviyoTrack = async (user, actionEvent, id) => {
    await axios({
      method: 'GET',
      url: `/api/klaviyoTrack`,
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
      },
      params: {
        user,
        actionEvent,
        id:id,
      },
    })
      .catch(e => {
        console.log(e);
      });
  };


  useEffect(() => {
    getChannelAccessToken();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        token,
        refreshToken,
        tokenExpires,
        updateToken,
        setToken,
        updateRefreshToken,
        updateTokenExpires,
        // signOut,
        user,
        setUser,
        hasErrors,
        setHasErrors,
        updateUser,
        isCustomerPanelOpen,
        setIsCustomerPanelOpen,
        registerCustomer,
        loginCustomer,
        authOrderId,
        createCustomerPasswordReset,
        logoutCustomer,
        updateCustomerPasswordReset,
        klaviyoTrack,
        addCustomerPassword,
        addedPassword,
        setAddedPassword,
        toggleCustomerPanel,
      }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
