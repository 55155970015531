import React from 'react';

const CartContext = React.createContext({
  cartId: null,
  order: [],
  orderId: null,
  addressId: null,
  error: null,
  isLoading: null,
  cartOpen: null,
  userOrders: [],
  skusCount: null,
  hasCouponError: false,
  // cartCount: 0,
  // addToCart: () => {},
  // updateCartCount: () => {},
  fetchOrder: () => {},
  updateOrder: () => {},
  createLineItem: () => {},
  deleteLineItem: () => {},
  createAddress: () => {},
  checkoutGuest: () => {},
  setCartOpen: () => {},
  setOrderId: () => {},
  fetchUserOrders: () => {},
  setIsLoading: () => {},
  fetchProductPrices: () => {},
  categoryPrices: [],
  token: null,
  fetchProductStock: () => {},
  productCurrentInventory: null,
  updateOrderCustomerDetails: () => {},
  updateOrderShipmentMethod: () => {},
  updateOrderPaymentMethod: () => {},
  fetchCartProductStock: () => {},
  cartProductsStock: [],
  outOfStockError: null,
  setOutOfStockError: () => {},
  addToCartSuccess: null,
  setAddToCartSuccess: () => {},
  cartCheckoutReady: null,
  setCartCheckoutReady: () => {},
  orderDetails: null,
  isOrderDetailOpen: null,
  toggleOrderDetailOpen: () => {},
  setUserOrders: () => {},
  searchOpen: null,
  setSearchOpen: () => {},
});

export default CartContext;
