import { Box, Text, Flex, Heading } from 'theme-ui';
import Link from 'next/link';
import styled from '@emotion/styled';
// import Collapsible from 'react-collapsible';
import { useMediaQuery } from 'react-responsive';
// import Logo from '../logo';

import LogoBioSpajz from '@/components/logoBiospajz';

const HelloLove = ({ title, children, footerColumnWidths }) => {
  return (
    <>
      {/* <Box
        sx={{
          display: ['block', 'block', 'none'],
          mb: [0, 0],
          mt: [0, 0, 0, 4],
          width: '100%',
          '& .Collapsible': {
            borderBottom: '1px solid gray',
          },
          '& .Collapsible__trigger': {
            display: 'block',
            py: 4,
            // marginBottom: "2",
            fontWeight: '900',
            fontSize: 4,
            textAlign: 'center',
            mb: 0,
          },
          '& .Collapsible__contentOuter': {
            pl: 0,
          },
          '& .Collapsible__contentInner': {
            mb: 4,
          },
        }}>
        <Collapsible trigger={title}>{children}</Collapsible>
      </Box> */}
      <Box
        sx={{
          display: ['flex', 'none', 'flex'],
          mb: [5, 6, 0],
          mt: [0, 0, 0, 4],
          width: footerColumnWidths,
          flexDirection: 'column',
          alignItems: ['center', 'center', 'flex-start'],
        }}>
        <Heading
          as="h3"
          sx={{
            mb: [3, 4, 2],
            fontSize: [5, 7, 4],
          }}>
          {title}
        </Heading>
        {children}
      </Box>
    </>
  );
};


const Footer = prop => {
  const footerColumnWidths = ['100%', '100%', '50%', '25%'];
  const StyledLink = styled(Link)`
    text-decoration: none;
    color: inherit;
  `;

  const liStyled = {
    listStyle: 'none',
    marginBottom: [2, 1],
    fontSize: [3, 5, 1],
    lineHeight: [1.75, 1.9, 1.75],
    textAlign: ['center', 'center', 'left'],
  };

  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <Flex
      as="footer"
      sx={{
        pt: [3, 3, 3, 3, 6],
        pb: [124, 0],
        px: [0, 0, 80],
        bg: 'primaryGreen',
        color: 'white',
        fontSize: 3,
        flexDirection: 'column',
        justifyContent: ['center', 'center', 'auto'],
      }}>
      <Flex
        // width={[1, 1, 1, "1024px"]}
        sx={{
          m: "auto",
          mb: [2, 2, 2, 6],
          pl: 0,
          pt: [5, 5, 0],
          width: ['auto', 'auto', '100%'],
          height: '100%',
          flexDirection: ['column', 'column', 'row', 'row'],
        }}>
        <Box
          sx={{
            display: 'flex',
            width: footerColumnWidths,
            flexDirection: 'column',
            alignItems: ['center', 'center', 'flex-start'],
            textAlign: ['center', 'center', 'left'],
            mb: [6, 6, 0],
          }}>
          <Box
            sx={{
              width: [180, 300, 140],
              position: 'relative',
              height: ['auto', 'auto', '60px'],
            }}>
            <LogoBioSpajz />
          </Box>

          {/* <Text
            as="span"
            sx={{
              display: 'block',
              fontSize: [3, 4, 1],
              width: ['80%', '60%', '100%'],
              fontWeight: 100,
              pl: [0, 0, 3],
              mt: 3,
            }}>
            Danas je lep dan da uzmes nesto i prezalogajis, jer je sve lepo
          </Text> */}

          {/* <Box
            className="sm"
            sx={{
              display: 'flex',
              width: '100%',
              pl: [0, 0, 3],
              mt: 3,
              justifyContent: ['center', 'center', 'flex-start'],
            }}>
            <Box
              sx={{
                width: [36, 36, 24],
                height: [36, 36, 24],
                bg: 'white',
                borderRadius: 16,
                mr: 2,
              }}
            />
            <Box
              sx={{
                width: [36, 36, 24],
                height: [36, 36, 24],
                bg: 'white',
                borderRadius: 16,
              }}
            />
          </Box> */}
        </Box>

        <HelloLove
          footerColumnWidths={footerColumnWidths}
          title="Online kupovina">
          <Box
            as="ul"
            sx={{
              textAlign: ['center', 'center', 'left'],
              pl: 0,
              '& > li': {
                ...liStyled,
              },
              '& > li > a:hover': {
                fontWeight: '900',
              },
            }}>
            {/* <li>
              <StyledLink passHref href="/">Uputstvo za online kupovinu</StyledLink>
            </li> */}
            <li>
              <StyledLink passHref href="/info/uslovi-koriscenja"><a>Uslovi korišćenja</a></StyledLink>
            </li>
            <li>
              <StyledLink passHref href="/info/nacini-placanja"><a>Načini plaćanja</a></StyledLink>
            </li>
            <li>
              <StyledLink passHref href="/info/nacini-porucivanja">
                <a>Načini poručivanja</a>
              </StyledLink>
            </li>
            <li>
              <StyledLink passHref href="/info/nacin-isporuke"><a>Načini isporuke</a></StyledLink>
            </li>
            <li>
              <StyledLink passHref href="/info/pomoc"><a>Česta pitanja</a></StyledLink>
            </li>
          </Box>
        </HelloLove>

        <HelloLove footerColumnWidths={footerColumnWidths} title="Kompanija">
          <Box
            as="ul"
            sx={{
              textAlign: ['center', 'center', 'left'],
              pl: 0,
              '& > li': {
                ...liStyled,
              },
              '& > li > a:hover': {
                fontWeight: '900',
              },
            }}>
            <li>
              <StyledLink passHref href="/o-nama"><a>O nama</a></StyledLink>
            </li>
            <li>
              <StyledLink passHref href="/info/podaci-o-trgovcu"><a>Podaci o trgovcu</a></StyledLink>
            </li>
            {/* <li>
              <StyledLink passHref href="/">Zaposlenje</StyledLink>
            </li> */}
            <li>
              <StyledLink passHref href="/prodavnice"><a>Prodavnice</a></StyledLink>
            </li>
            <li>
              <StyledLink passHref href="/veleprodaja"><a>Veleprodaja</a></StyledLink>
            </li>
            {/* <li><StyledLink passHref href="/politika-privatnosti">Politika privatnosti</StyledLink></li>
                        <li><StyledLink passHref href="/politika-kolacica">Politika kolačića</StyledLink></li> */}
          </Box>
        </HelloLove>

        <HelloLove
          footerColumnWidths={footerColumnWidths}
          title="Grupe proizvoda">
          <Box
            as="ul"
            sx={{
              textAlign: ['center', 'center', 'left'],
              pl: 0,
              '& > li': {
                ...liStyled,
              },
              '& > li > a:hover': {
                fontWeight: '900',
              },
            }}>
            <li>
              <StyledLink passHref href="/osnovne-namirnice"><a>Osnovne namirnice</a></StyledLink>
            </li>
            <li>
              <StyledLink passHref href="/organski-proizvodi">
                <a>Organski proizvodi</a>
              </StyledLink>
            </li>
            <li>
              <StyledLink passHref href="/lekoviti-dodaci"><a>Lekoviti dodaci</a></StyledLink>
            </li>
            <li>
              <StyledLink passHref href="/kozmetika-i-higijena">
                <a>
                Kozmetika i higijena
                </a>
              </StyledLink>
            </li>
            <li>
              <StyledLink passHref href="/posebna-ishrana"><a>Posebna ishrana</a></StyledLink>
            </li>
            <li>
              <StyledLink passHref href="/zdravi-napici"><a>Zdravi napici</a></StyledLink>
            </li>
            <li>
              <StyledLink passHref href="/razni-proizvodi"><a>Razni proizvodi</a></StyledLink>
            </li>
          </Box>
        </HelloLove>

        <HelloLove
          footerColumnWidths={footerColumnWidths}
          title="Ostale stranice">
          <Box
            as="ul"
            sx={{
              textAlign: ['center', 'center', 'left'],
              pl: 0,
              '& > li': {
                ...liStyled,
              },
              '& > li > a:hover': {
                fontWeight: '900',
              },
            }}>
            <li>
              <StyledLink passHref href="/recepti"><a>Recepti</a></StyledLink>
            </li>
            <li>
              <StyledLink passHref href="/saveti"><a>Saveti</a></StyledLink>
            </li>
          </Box>
        </HelloLove>
      </Flex>

      <Box
        as="ul"
        sx={{
          textAlign: ['center', 'center', 'left'],
          mt: 4,
          pl: 0,
          '& > li': {
            ...liStyled,
          },
          '& > li > a:hover': {
            fontWeight: '900',
          },
        }}>
        {/* <li><StyledLink passHref href="/">Politika privatnosti</StyledLink></li>
                        <li><StyledLink passHref href="/">Za šta koristimo kolačiće</StyledLink></li> */}
      </Box>
      <Flex
        sx={{
          // bg: "#032317",
          py: 3,
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <Text
          sx={{
            fontSize: [3, 3, 2],
            opacity: 0.8,
          }}>
          Bio Špajz - sva prava zadržana © {new Date().getFullYear()}
        </Text>
      </Flex>
    </Flex>
  );
};

export default Footer;
