import { useEffect } from 'react';
import { Box, Flex, Text } from 'theme-ui';
import Link from 'next/link';
import { animated } from 'react-spring';
import AlertOutsideWrapper from '@/components/AlertOutsideWrapper';
// import HomeIcon from './home.inline.svg?name=HomeIcon';

const MobilePageSideBar = ({ styleX, isOpen, closeSideBar }) => {


  const SpringBox = animated(Box);
  const AnimatedBg = animated(Box);
  // const springProp = useSpring({ to: { transform: `translateX(${isOpen ? '0px' : '-250px'})`}})

  useEffect(() => {
    // console.log('mounted Mobile Page SideBar')
    // return (
    // console.log('demounted Mobile Page SideBar')
    // )
  }, []);

  // console.log('rendering Mobile Page Side Bar')

  const pages = [
    {
      title: 'Proizvodi',
      link: '/categories',
    },
    {
      title: 'Saveti',
      link: '/saveti',
    },
    {
      title: 'Recepti',
      link: '/recepti',
    },
    {
      title: 'Naša priča',
      link: '/o-nama',
    },
    {
      title: 'Dostava',
      link: '/info/nacin-isporuke',
    },
    {
      title: 'Prodavnice',
      link: '/prodavnice',
    },
    // {
    //   title: "Vaša pitanja",
    //   link: "/vasa-pitanja"
    // },
    {
      title: 'Veleprodaja',
      link: '/veleprodaja',
    },
    // {
    //   title: "Video",
    //   link: "/video"
    // },
    {
      title: 'Kontakt',
      link: '/kontakt',
    },
  ];

  return (
    <AlertOutsideWrapper togglerFunction={closeSideBar} isActive={isOpen}>
      <AnimatedBg
        // ref={springBg}
        onClick={() => closeSideBar()}
        sx={{
          display: `${isOpen ? 'block' : `none`}`,
          position: 'fixed',
          width: '100vw',
          height: '100vh',
          bg: 'black',
          opacity: '0.3',
          top: 0,
          left: 0,
          zIndex: 101,
          cursor: 'pointer',
        }}
      />
      <SpringBox
        style={styleX}
        sx={{
          position: 'fixed',
          left: 0,
          top: '0px',
          height: '100vh',
          width: ['250px', '250px', '250px', '400px'],
          bg: 'primaryOrange',
          zIndex: 102,
          pt: 5,
        }}>
        <Box
          sx={{
            position: 'absolute',
            top: 3,
            right: 4,
          }}
          onClick={() => closeSideBar()}>
          {/* <HomeIcon /> */}
        </Box>
        <Flex
          as="ul"
          sx={{
            alignItems: 'center',
            flexDirection: 'column',
            fontFamily: 'body',
            fontWeight: 900,
            display: ['flex', 'flex', 'flex', 'none'],
            height: '100%',
            justifyContent: 'space-evenly',
          }}>
          {pages.map(x => (
            <Box
              onClick={closeSideBar}
              as="li"
              mt={1}
              key={x.link}
              sx={{
                listStyle: 'none',
                py: 0,
                width: '100%',
                pl: 4,
                fontSize: [4, 2, 2, 2, 2, 3, 4],
                fontWeight: 600,
                letterSpacing: '0.2px',
                color: 'white',
                cursor: 'pointer',
                transition: 'all 0.25s ease-in-out',
                '&:hover': {
                  color: 'darkGrey',
                  transition: 'all 0.25s ease-in-out',
                  bg: '#eee',
                },
              }}>
              <Link href={x.link || "/"} passHref>
                <Box as="a">
                  <Text className="nav-item">{x.title}</Text>
                </Box>
              </Link>
            </Box>
          ))}
        </Flex>
      </SpringBox>
    </AlertOutsideWrapper>
  );
};

export default MobilePageSideBar;
