import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import AuthContext from './AuthContext';
import CartContext from './CartContext';
import {handleTax} from '../Helpers/handleTax';

const CartProvider = ({ children }) => {
  const { user, updateUser, token, authOrderId } = useContext(AuthContext);
  const [orderId, setOrderId] = useState(null);
  const [order, setOrder] = useState(null);
  const [addressId, setAddressId] = useState(null);
  const [error, setSerror] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [shipmentId, setShipmentId] = useState(null);
  const [availablePaymentMethod, setAvailablePaymentMethod] = useState(null);
  const [cartOpen, setCartOpen] = useState(false);
  const [userOrders, setUserOrders] = useState(null);
  const [skusCount, setSkusCount] = useState(null);
  const [hasCouponError, setHasCouponError] = useState(false);
  const [orderItemsDato, setOrderItemsDato] = useState(null);
  const [categoryPrices, setCategoryPrices] = useState(null);
  const [productCurrentInventory, setProductCurrentInventory] = useState(null);
  const [cartProductsStock, setCartProductsStock] = useState([]);
  const [outOfStockError, setOutOfStockError] = useState(false);
  const [addToCartSuccess, setAddToCartSuccess] = useState(false);
  const [checkoutStepCompleted, setCheckoutStepCompleted] = useState(0);
  const [placedOrder, setPlacedOrder] = useState(null);
  const [cartCheckoutReady, setCartCheckoutReady] = useState(false);
  const [orderDetails, setOrderDetails] = useState(null);
  const [isOrderDetailOpen, toggleOrderDetailOpen] = useState(null);
  const [searchOpen, setSearchOpen] = useState(false);

  function isLocalStorageAvailable() {
    try {
        var test = '__test__';
        localStorage.setItem(test, test);
        localStorage.removeItem(test);
        return true;
    } catch (e) {
        return false;
    }
  }

  const createOrder = async () => {
    // If user exists (loggedin)
    // test if users logged in than created order
    const userEmail = user
      ? user && user.data && user.data.attributes.email
      : undefined;
    let orderId = null;
    const res = await axios.get(
      `/api/createOrder`,
      {
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
        },
        params: {
          token,
        },
      }
    );
    setOrderId(res.data.data.id);
    if(isLocalStorageAvailable()) localStorage.setItem('orderId', res.data.data.id);
    // const shipmentIds = res.data.included.filter(x => x.type == "shipments")
    // setShipmentId(shipmentIds[0].id)
    setOrder(res.data);
    setSkusCount(res.data.data.relationships.line_items.data.length || 0);
    // setAvailablePaymentMethod(res.data.data.relationships.available_payment_methods.data[0].id)
    orderId = await res.data.data.id;
    return res.data.data.id;
  };

  const fetchOrder = (orderId, scenario) => {
    axios
      .get(`/api/fetchOrder`, {
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
        },
        params: {
          orderId,
          token,
        },
      })
      .then(res => {
        if (scenario === 'orderDetailsPage') {
          setOrderDetails(res.data);
        } else {
          setOrder(res.data);
          setSkusCount(res.data.data.relationships.line_items.data.length || 0);
          setOrderId(res.data.data.id);
          const shipmentIds = res.data.included.filter(
            x => x.type === 'shipments'
          );
          if (shipmentIds.length > 0) setShipmentId(shipmentIds[0].id);
          setAvailablePaymentMethod(
            res.data.data.relationships.available_payment_methods.data[0].id
          );

          // console.log('magacin', res.data.data.attributes.status);

          // If order placed save it to a different value
          if (res.data.data.attributes.status == 'placed') {
            // console.log('before')
            klaviyoPlacedOrder(res.data);
            // console.log('after');
            setPlacedOrder(res.data);
            // if its placed it means it's the last step and we will delete the order from the regular memory
            deleteOrder();
          }
        }

        // TO DO: UPDATE USER WHEN FETCHING ORDER
        // I DONT REMEMBER WHY?~_~ I wrote this
        // if(res.data.included) updateUser(res.data.included)
        // console.log('++++++++++user', user)
      })
      .catch(e => console.log(e));
  };

  const klaviyoPlacedOrder = order => {
    // console.log('klaviyoPlacedOrder', order)
    // Formatting acording to KLAVIYO API\
    const skusOnly = order.included.filter(x => x.type == "skus")
    const skus = skusOnly.map(x => {
        const lineItem = order.included.find(y => y.type == "line_items" && y.attributes.sku_code == x.attributes.code)
        // console.log('lineItems', lineItem);
        return {
            productId: x.attributes.code,
            sku: x.attributes.code,
            productName: x.attributes.name,
            quantity: lineItem.attributes.quantity,
            taxCategory: x.attributes.metadata.taxCategory,
            itemPrice: lineItem.attributes.unit_amount_cents / 100,
            rowTotal: lineItem.attributes.total_amount_cents / 100,
        }
    })

    // console.log('skus', skus)

    const shippingAddressId = order.data.relationships.shipping_address.data.id
    const shippingAddress = order.included.find(x => x.id == shippingAddressId)
    // console.log('shippingAddress', shippingAddress, order)
    const orderKlaviyoFormat = {
        customerProperties: {
            email: order.data.attributes.customer_email,
            firstName: shippingAddress.attributes.first_name,
            lastName: shippingAddress.attributes.last_name,
            phoneNumber: shippingAddress.attributes.phone,
            address1: `${shippingAddress.attributes.line_1} ${shippingAddress.attributes.line_2}`,
            address2: ``,
            city: shippingAddress.attributes.city,
            zip: shippingAddress.attributes.zip_code,
            region: shippingAddress.attributes.metadata.opstinaLabel,
            country: shippingAddress.attributes.state_code,
        },
        properties: {
            eventId: order.data.id,
            value: order.data.attributes.total_taxable_amount_cents / 100,
            orderId: order.data.attributes.number,
            taxTotal: handleTax(order),
            valueNoTax: order.data.attributes.total_taxable_amount_cents / 100 - handleTax(order),
            itemNames: skus.map(x => x.productName),
            items: skus,
            billingAddress: {
                firstName: shippingAddress.attributes.first_name,
                lastName: shippingAddress.attributes.last_name,
                company: "",
                phoneNumber: shippingAddress.attributes.phone,
                address1: `${shippingAddress.attributes.line_1} ${shippingAddress.attributes.line_2}`,
                address2: ``,
                city: shippingAddress.attributes.city,
                zip: shippingAddress.attributes.zip_code,
                region: shippingAddress.attributes.metadata.opstinaLabel,
                country: shippingAddress.attributes.state_code,
            },
            shippingAddress: {
                firstName: shippingAddress.attributes.first_name,
                lastName: shippingAddress.attributes.last_name,
                company: "",
                phoneNumber: shippingAddress.attributes.phone,
                address1: `${shippingAddress.attributes.line_1} ${shippingAddress.attributes.line_2}`,
                address2: ``,
                city: shippingAddress.attributes.city,
                zip: shippingAddress.attributes.zip_code,
                region: shippingAddress.attributes.metadata.opstinaLabel,
                country: shippingAddress.attributes.state_code,
            },
        },
        time: Math.floor(new Date().getTime() / 1000)
    }




    axios({
      method: 'GET',
      url: `/api/klaviyoPlacedOrder`,
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
      },
      params: {
        orderKlaviyoFormat
      }
    })
  }

  const fetchUserOrders = customerId => {
    token !== null && axios({
      method: 'GET',
      url: `/api/fetchUserOrders`,
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
      },
      params: {
        customerId,
        token,
      },
    }).then(res => {
      setUserOrders(res.data);
    });
  
  };

  const updateOrder = async (update, existingAddressId, paymentMethodId) => {
    // console.log('tracking++', update)sono
    // setIsLoading(true);
    await axios({
      method: 'GET',
      url: `/api/updateOrder`,
      headers: {
        'Content-Type': 'application/vnd.api+json',
        Accept: 'application/vnd.api+json',
        Authorization: `Bearer ${token}`,
      },
      params: {
        update,
        orderId,
        token,
        existingAddressId,
        paymentMethodId,
      },
    })
      .then(res => {
        setOrder(res.data);
        setSkusCount(res.data.data.relationships.line_items.data.length || 0);
        // console.log('orderUpdate - response', data)
        // setIsLoading(false);
        setHasCouponError(false);
        // setError(null);
        // console.log('upudatedOrder', order)
        // setOrderUserId(data.data.included[0])
        // console.log('user----', orderUserId)
      })
      .catch(e => {
        console.log(e.response);
        setSerror(e.response);
        setIsLoading(false);
      });
  };

  // delete local order
  const deleteOrder = async () => {
    setOrder(null);
    setOrderId(null);
    if(isLocalStorageAvailable()) localStorage.removeItem('orderId');
    if(isLocalStorageAvailable()) localStorage.removeItem('order');
    createOrder();
    setSkusCount(0);
  };

  // Step create address, customer, update order
  const updateOrderCustomerDetails = async (values, existingAddressId) => {
    try {
      let email = null;
      email = { customer_email: values.email };
      const isLoggedUserEmail = () => {
        if (user) {
          return user.data.attributes.email
            ? user.data.attributes.email
            : false;
        }
        return false;
      };

      // * Update Order with customer email
      if (isLoggedUserEmail() === false) {
        // new customer
        const updateObj = {
          ...email,
          metadata: {
            beleska: values.note,
          },
        };
        // add customer email and address notes to the order association
        await updateOrder(updateObj);
      } else {
        const updateObj = {
          ...email,
          metadata: {
            beleska: values.note,
          },
        };
        // existing customer
        // TO DO: testing without object just patch due to customer specific token
        // FUCKING ALWAYS WRITE WHAT IS THE SCENARIO - DONT KNOW WHAT / WHY / IS THIS NEEDED UPDATEORDER HERE - I AM a DUMBASS

        // DONT SEE THE DIFFERENCE IF ITS LOGGED IN OR NOT
        // ORDER UPDATES NO MATTER - WITH NEW ORDER NOTES AND PUT EMAIL
        await updateOrder(updateObj);
      }
      // * See if OrderAddressId to update it, otherwise create new
      if (existingAddressId) {
        const resAddressId = await updateAddress(values, existingAddressId);
        const obj = {
          _billing_address_same_as_shipping: '1',
          _save_shipping_address_to_customer_address_book: user ? '1' : '0',
          metadata: {
            stepCompleted: 'customerDetails',
          },
        };
        // await setAddressShippingSameBilling(resAdressId)
        await updateOrder(obj, resAddressId);
      } else {
        const resAddressId = await createAddress(values);
        const metadata = {
          _billing_address_same_as_shipping: '1',
          _save_shipping_address_to_customer_address_book: user ? '1' : '0',
          metadata: {
            stepCompleted: 'customerDetails',
          },
        };
        // set that persons address is the same as billing address
        // for now its the simple UX flow (single address field)
        // await setAddressShippingSameBilling(resAdressId)
        await updateOrder(metadata, resAddressId);
      }
      // * Set Checkout Step to move forward UI
      setCheckoutStepCompleted(1);
    } catch (error) {
      console.log('error', error);
    }
  };

  const updateOrderShipmentMethod = async () => {
    try {
      // set shipping method
      // BeX
      const shipping = await getShipments();
      await updateShippingMethod(shipping);
      const obj = {
        metadata: {
          stepCompleted: 'deliveryOptions',
        },
      };
      await updateOrder(obj);
      setCheckoutStepCompleted(2);
    } catch (error) {
      console.log('updateordershipmentmethod', error);
    }
  };

  const updateOrderPaymentMethod = async values => {
    try {
      // set payment method
      // HARDCODED AS WE DONT HAVE PAYMENT METHODS AT THE MOMENT
      await CreateWireTransfer();
      const paymentMethodId = 'wmVXLsBPAM';
      const obj = {
        _place: 1,
        metadata: {
          stepCompleted: 'paymentOptions',
        },
      };
      // await updatePaymentMethod()
      await updateOrder(obj, null, paymentMethodId);

      setCheckoutStepCompleted(3);
      fetchOrder(orderId);
      setIsLoading(false);
    } catch (error) {
      console.log('updateOrderPaymentMethod', error);
    }
  };

  const createAddress = async values => {
    // setLoading(true)
    let resAddressId = null;
    await axios({
      method: 'GET',
      url: `/api/createAddress`,
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
      },
      params: {
        values,
        token,
      },
    })
      .then(res => {
        setAddressId(res.data.data.id);
        resAddressId = res.data.data.id;
        return resAddressId;
      })
      .catch(err => {
        console.log('err', err);
      });
    return resAddressId;
  };

  const updateAddress = async (values, existingAddressId) => {
    // setLoading(true)
    let resAddressId = null;
    await axios({
      method: 'GET',
      url: `/api/updateAddress`,
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
      },
      params: {
        values,
        existingAddressId,
        token,
      },
    })
      .then(res => {
        setAddressId(res.data.data.id);
        resAddressId = res.data.data.id;
        return resAddressId;
      })
      .catch(err => {
        console.log('err', err);
      });
    return resAddressId;
  };

  // ** USING UPDATEORDER instead of this
  // const setAddressShippingSameBilling = async adresaId => {
  //   console.log("0000000", adresaId)
  //   await axios({
  //     method: 'GET',
  //     url: `/api/setAddressShippingSameBilling`,
  //     headers: {
  //       accept: "application/json",
  //       'content-type': "application/json",
  //     },
  //     params: {
  //       orderId,
  //       user,
  //       adresaId,
  //       token,
  //     }
  //   })
  //   .then(res => {return res})
  //     .catch(err => {
  //       console.log('err', err)
  //     })
  //   return
  // }

  const createLineItem = async (itemSku, quantity, isRinfuz, isUpdated, noPopup) => {
    setIsLoading(true);
    const updateLineItem =
      (await order) &&
      order.included &&
      order.included.find(item => item.attributes.sku_code == itemSku);
    const lineItemId = (await updateLineItem) && updateLineItem.id;
    let localOrderId;
    orderId === null
      ? (localOrderId = await createOrder())
      : (localOrderId = orderId);
    setAddToCartSuccess(false);
    setOutOfStockError(false);
    if (isUpdated) {
      try {
        axios({
          method: 'GET',
          url: `/api/updateLineItem`,
          headers: {
            accept: 'application/json',
            'content-type': 'application/json',
          },
          params: {
            lineItemId,
            orderId,
            token,
            quantity,
            isRinfuz,
          },
        })
          .then(res => {
            setIsLoading(false);
            console.log('=====', res)
            if (res.data.message === 'out of stock') {
              console.log('1')
              setIsLoading(false);
              setAddToCartSuccess(false);
              setOutOfStockError(true);
            } else if (res.data.message === 'not enough stock') {
              console.log('2')
              setIsLoading(false);
              setOutOfStockError({ inventoryLeft: res.data.inventoryLeft });
            } else {
              console.log('3')
              setIsLoading(false);
              setAddToCartSuccess(!noPopup);
              fetchOrder(orderId);
            }
          })
          .catch(e => {
            // console.log('as');
            console.log(e);
          });
      } catch (error) {
        setIsLoading(false);
        console.log(error);
      }
    } else {
      await axios({
        method: 'GET',
        url: `/api/createLineItem`,
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
        },
        params: {
          token,
          itemSku,
          quantity,
          orderId: localOrderId,
          isUpdated,
          isRinfuz,
        },
      })
        .then(res => {
          if (res.data.message === 'out of stock') {
            setIsLoading(false);
            setOutOfStockError(true);
            setAddToCartSuccess(false);
          } else if (res.data.message === 'not enough stock') {
            setIsLoading(false);
            setOutOfStockError({ inventoryLeft: res.data.inventoryLeft });
          } else {
            setIsLoading(false);
            fetchOrder(localOrderId), setIsLoading(false);
            setAddToCartSuccess(!noPopup);
          }
        })
        .catch(error => {
          console.log('++error', error.statusCode)
          setIsLoading(false);
        });

      // return res
    }

    // if (updateLineItem) {
    //   console.log('orderId', orderId)

    // } else {
    //   console.log('createLineItem', itemSku, quantity, localOrderId, orderId)

    // }
  };

  const deleteLineItem = async itemId => {
    await axios({
      method: 'GET',
      url: `/api/deleteLineItem`,
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
      },
      params: {
        itemId,
        token,
      },
    })
      .then(res => {
        fetchOrder(orderId);
      })
      .catch(e => console.log(e));
  };

  const CreateWireTransfer = async values => {
    let wireTransferId = null;
    await axios({
      method: 'GET',
      url: `/api/createWireTransfer`,
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
      },
      params: {
        orderId,
        token,
      },
    }).then(res => {
      wireTransferId = res.data.data.id;
    });
    return wireTransferId;
  };

  // const updatePaymentMethod = async () => {
  //   await axios({
  //     method: 'GET',
  //     url: `/api/updatePaymentMethod`,
  //     headers: {
  //       accept: "application/json",
  //       'content-type': "application/json",
  //     },
  //     params: {
  //       orderId,
  //       token
  //     }
  //   })
  // }

  const getShipments = async () => {
    let shipping = {
      shippingMethodId: null,
      shippingId: null,
    };
    await axios({
      method: 'GET',
      url: `/api/getShipments`,
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
      },
      params: {
        orderId,
        token,
      },
    })
      .then(res => {
        shipping = {
          shippingMethodId: res.data.included[0].id,
          shippingId: res.data.data[0].id,
        };
      })
      .catch(e => console.log(e));
    return shipping;
  };

  const updateShippingMethod = async shipping => {
    const shipmentId = shipping.shippingId;
    const shipmentMethodId = shipping.shippingMethodId;
    await axios({
      method: 'GET',
      url: `/api/updateShippingMethod`,
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
      },
      params: {
        shipmentId,
        shipmentMethodId,
        token,
      },
    });
  };

  const fetchProductPrices = async onPageCodes => {
    if (token !== null) {
      let productsPageCount = 1;
      let currentPage = 1;
      const productsData = [];
      const fetchedProducts = await axios({
        method: 'GET',
        url: `/api/getProductCategoryPrices`,
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
        },
        params: {
          onPageCodes: JSON.stringify(onPageCodes),
          token,
        },
      });
      productsData.push(...fetchedProducts.data.included);
      productsPageCount = fetchedProducts.data.meta.page_count;
      let nextLink = fetchedProducts.data.links.next;
      while (currentPage < productsPageCount) {
        const y = await axios({
          method: 'GET',
          url: `/api/getProductCategoryPrices`,
          headers: {
            accept: 'application/json',
            'content-type': 'application/json',
          },
          params: {
            onPageCodes: JSON.stringify(onPageCodes),
            token,
            nextLink,
          },
        });
        nextLink = y.data.links.next;
        productsData.push(...y.data.included);
        currentPage++;
      }
      setCategoryPrices(productsData);
    }
  };

  // for multiple products used on Cart Page
  const fetchCartProductStock = async onPageCodes => {
    try {
      // console.log('onPageCodes',onPageCodes)
      setCartProductsStock([]);
      const axiosCall = async item =>
        await axios({
          method: 'GET',
          url: `/api/fetchClProductStock`,
          headers: {
            accept: 'application/json',
            'content-type': 'application/json',
          },
          params: {
            productId: item,
            token,
          },
        });
      const getData = async () => {
        return Promise.all(onPageCodes.map(item => axiosCall(item)));
      };
      getData().then(res => {
        // FIXED HERE SOMETHING IS LATE WITH DATA
        console.log('res', res)
        const attributes = res.map(x => x && x.data && x.data[0] && x.data[0].stock_items[0]);
        setCartProductsStock(attributes);
      });
    } catch (error) {
      console.log(error);
    }
  };

  const fetchProductStock = async productId => {
    if (token !== null) {
      await axios({
        method: 'GET',
        url: `/api/fetchClProductStock`,
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
        },
        params: {
          productId,
          token,
        },
      }).then(res => {
        setProductCurrentInventory(res.data[0]?.stock_items[0].quantity);
      });
    }
  };

  useEffect(() => {
    // if orderId from not-logged-in user
    let localOrderId
    // if(isLocalStorageAvailable()) localStorage.getItem('orderId');

    // if its OrderId from not-logged-in user
    if (localOrderId && token) {
      fetchOrder(localOrderId);
    }

    // if its OrderId from logged in User
    if (authOrderId && token) {
      fetchOrder(authOrderId);
      if(isLocalStorageAvailable()) localStorage.setItem('orderId', authOrderId);
    }
  }, [token, authOrderId]);

  return (
    <CartContext.Provider
      value={{
        orderId,
        createLineItem,
        deleteLineItem,
        fetchOrder,
        updateOrder,
        createOrder,
        deleteOrder,
        setOrderId,
        setOrder,
        order,
        createAddress,
        addressId,
        setAddressId,
        getShipments,
        setShipmentId,
        setUserOrders,
        error,
        cartOpen,
        setCartOpen,
        userOrders,
        fetchUserOrders,
        skusCount,
        setIsLoading,
        isLoading,
        hasCouponError,
        setOrderItemsDato,
        orderItemsDato,
        // allPrices,
        fetchProductPrices,
        categoryPrices,
        token,
        fetchProductStock,
        productCurrentInventory,
        updateOrderCustomerDetails,
        updateOrderShipmentMethod,
        updateOrderPaymentMethod,
        fetchCartProductStock,
        placedOrder,
        cartProductsStock,
        outOfStockError,
        setOutOfStockError,
        addToCartSuccess,
        setAddToCartSuccess,
        checkoutStepCompleted,
        setCheckoutStepCompleted,
        cartCheckoutReady,
        setCartCheckoutReady,
        orderDetails,
        isOrderDetailOpen,
        toggleOrderDetailOpen,
        setSearchOpen,
        searchOpen,
      }}>
      {children}
    </CartContext.Provider>
  );
};

export default CartProvider;
