import React, { useEffect, useState, useContext } from 'react';
import { connectSearchBox } from 'react-instantsearch-dom';

// import { SearchIcon, Form  } from "./styles"
import { Input } from 'theme-ui';
import { Box, Button } from 'theme-ui';
import { useForm } from 'react-hook-form';
// import {navigate} from 'gatsby';
import { useRouter } from 'next/router';
import CartContext from '@/components/Contexts/CartContext';

export default connectSearchBox(
  ({ refine, currentRefinement, delay, ...rest }) => {
    const [query, setQuery] = useState(currentRefinement);
    const { searchInput, handleSubmit, watch, errors } = useForm();
    const { searchOpen, setSearchOpen } = useContext(CartContext);
    const [firstTime, setFirstTime] = useState(true)

    const router = useRouter()
    const paramQuery = router.query

    const onSubmit = data => {
      refine(query);
      router.push(`/pretraga/?q=${query}`);
    };

    useEffect(() => {
      if (firstTime == true) {
        if (paramQuery && paramQuery.q) {

          refine(paramQuery.q);
          setQuery(paramQuery.q);
          setFirstTime(false)
        } else if (firstTime == false) {
        };
      } else {
        refine(query);
        setQuery('');
        setFirstTime(false)
      }
    }, [paramQuery]);

    useEffect(() => {
      // console.log('searchOpen INPUT', searchOpen)
    },[searchOpen])

    return (
      <Box
        sx={{
          display: 'flex',
          // border: '0.25px solid',
          // borderColor: 'primaryGreen',
          width: '100%',
          // bg: '#fff7ef',
          border: '1px solid #00673f',
          borderRadius: 10,
          alignItems: 'center',
          justifyContent: 'space-between',
          // py: [2,5],
          height: ['100%', 64],
          pl: [searchOpen ? 0 : 2, 3],
          pr: [searchOpen ? 0 : 0, 3],
          mb: 4,
        }}>
        <Box as="form"
          onSubmit={handleSubmit(onSubmit)}
          sx={{
            marginBottom: '0px',
            display: 'flex',
            flexDirection: [searchOpen ? 'column' : 'row', 'row'],
            alignItems: 'center',
            height: '100%',
            width: '100%',
            mt: [searchOpen ? '-10px' : '0px', '0px'],
          }}>
          {/* <SearchAlt2 sx={{ ml: [0,2], mr: [0,3] }} size="24" title="Unlock account" /> */}
          {!searchOpen && <svg
            width="21"
            height="21"
            sx={{
              display: [searchOpen ? 'none' : 'block', 'block'],
              ml: 2,
              mr: 1,
            }}
            viewBox="0 0 21 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M11.8486 15.5299C15.3859 14.0266 17.0347 9.94046 15.5314 6.40322C14.0281 2.86599 9.94196 1.21715 6.40473 2.72044C2.86749 4.22373 1.21866 8.30988 2.72194 11.8471C4.22523 15.3843 8.31138 17.0332 11.8486 15.5299Z"
              stroke="#1F1F1F"
              strokeWidth="1.66667"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M14.0488 14.0449L19.4461 19.443"
              stroke="#1F1F1F"
              strokeWidth="1.66667"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>}

          <Input
            autoFocus
            height="100%"
            mb={[searchOpen ? 5 : 0, 0]}
            type="text"
            ref={searchInput}
            value={query}
            placeholder="Pretraži proizvode, savete, recepte..."
            aria-label="Pretraži sajt"
            sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              width: [searchOpen ? '100%' : 'auto', 'auto'],
              fontWeight: 300,
              flexGrow: '1',
              p: 0,
              pt: [searchOpen ? 4 : 0, 0],
              pl: 3,
              border: '0',
              '&:focus': {
                border: 0,
                outline: 'none',
              },
            }}
            onChange={e => {
              setQuery(e.currentTarget.value);
              // e.persist()
              // debouncedSearch(e, e.eventTarget)\
            }}
            {...rest}
          />
          <Box
            sx={{
              width: [searchOpen ? '100%' : 'auto', 'auto'],
            }}>
            <Button
            onClick={() => setSearchOpen(false)}
              disabled={query.length == 0}
              sx={{
                variant: 'buttons.secondary',
                py: [3, 3, 2],
                px: [searchOpen ? 0 : 2, 3, 3],
                fontSize: [3, 4],
                width: [searchOpen ? '100%' : 'auto', 'auto'],
                borderRadius: '10px',
              }}>
              Pretraži
            </Button>
          </Box>
        </Box>
      </Box>
    );
  }
);
