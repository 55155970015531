// import '@assets/main.css'
// import '@assets/chrome-bug.css'
// import 'keen-slider/keen-slider.min.css'

import { FC, useEffect } from 'react'
// import { Head } from '@/components/common'
// import { ManagedUIContext } from '@/components/ui/context'
import { ThemeProvider } from 'theme-ui'
import theme from "../utils/themeui"
import AuthProvider from '@/components/Contexts/AuthProvider'
import CartProvider from "@/components/Contexts/CartProvider"
import { useRouter } from 'next/router'
import Script from 'next/script'

import '@styles/globals.css'

import Layout from "@/components/Layout"

// const Noop = ({ children }) => <>{children}</>

export default function MyApp({ Component, pageProps }) { 

  const router = useRouter()

  // useEffect(() => {
  //   const handleRouteChange = (url) => {
  //        window.gtag('config', 'UA-6065752-1', {
  //         page_path: url,
  //       })
  //   }
    
  //   //When the component is mounted, subscribe to router changes
  //   //and log those page views
  //   router.events.on('routeChangeComplete', handleRouteChange)

  //   // If the component is unmounted, unsubscribe
  //   // from the event with the `off` method
  //   return () => {
  //     router.events.off('routeChangeComplete', handleRouteChange)
  //   }
  // }, [router.events])
  // const Layout = (Component).Layout || Noop

  useEffect(() => {
    // console.log('TEeeeeest 1231231', window)
  }, [])

  return (
    <>
   <Script
          strategy='afterInteractive'
          src={`https://www.googletagmanager.com/gtag/js?id=G-4PDBJK0720`}
        />
        <Script
          strategy='afterInteractive'
        >
          {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'UA-6065752-1', {
                page_path: window.location.pathname,
              });
              gtag('config', 'G-4PDBJK0720', {
                page_path: window.location.pathname,
              });

            `}
        </Script>
      {/* <Head /> */}
      <ThemeProvider theme={theme}>
        <AuthProvider>
          <CartProvider>
            {/* <ManagedUIContext> */}
              <Layout pageProps={pageProps}>
                <Component {...pageProps} />
              </Layout>
            {/* </ManagedUIContext> */}
          </CartProvider>
        </AuthProvider>
      </ThemeProvider>
    </>
  )
}