import React from 'react';
import {
  InstantSearch,
  Index,
  Stats,
  InfiniteHits,
  Configure,
} from 'react-instantsearch-dom';

import { useRouter } from 'next/router';

import algoliasearch from 'algoliasearch/lite';

import {Box, Flex, Heading} from 'theme-ui';
import InputCustom from './input';
import * as hitComps from './hitComps';
import Autocomplete from './autocomplete';

export const searchIndices = [
  {name: `dev_PROIZVODI`, title: `Proizvodi`, hitComp: `ProizvodHit`},
  {name: `dev_PRODAVNICE`, title: `Prodavnice`, hitComp: `ProdavnicaHit`},
  {name: `dev_RECEPTI`, title: `Recepti`, hitComp: `RecepatHit`},
  {name: `dev_SAVETI`, title: `Saveti`, hitComp: `SavetHit`},
];

const Search = props => {
  const {pathname} = props;
  // let firstLoad = true

  const router = useRouter()
  const queryParams = router.query

  const algoliaClient = algoliasearch(
    'S4SSBP6IEU',
    '861f6ca4b3f2f64e5e7c9f028b295c7d'
  );
  
  const searchClient = {
    search(requests) {
      // if (firstLoad === true) {
      //   firstLoad = false;
      //   return;
      // }
      if (requests[0].params.query !== "" && queryParams && queryParams.q) return algoliaClient.search(requests);
      
    },
  };

  return (
    <Box
      sx={{
        width: '100%',
        position: 'relative',
      }}>
      <InstantSearch
        searchClient={searchClient}
        indexName={searchIndices[0].name}
        refresh>
        <Configure hitsPerPage={10} />
        <Flex
          sx={{
            width: '100%',
            height: '100%',
            mt: [0, 0, 0],
            px: [1, 0],
          }}>
          {/* {pathname.includes('pretraga') ? <InputCustom delay={1000} showLoadingIndicator defaultRefinement={urlParams.q} location={location}/> : <Autocomplete/>} */}
          <InputCustom
            // delay={1000}
            showLoadingIndicator
          />
        </Flex>
        {router.asPath.includes('pretraga') && (
          <Box
            sx={{
              position: 'relative',
              display: 'flex',
            }}>
            <Box
              sx={{
                width: ['100%', '100%', '100%', '100%', `${200 / 3}%`],
                mx: 'auto',
                mt: 4,
                height: [
                  'calc(100vh - 204px)',
                  'calc(100vh - 204px)',
                  'calc(100vh - 204px)',
                  '100%',
                ],
                overflow: ['auto', 'auto', 'auto'],
              }}>
              {searchIndices.map(({name, title, hitComp}) => (
                <Box mb={6} key={name}>
                  <Index key={name} indexName={name}>
                    <header>
                      <Heading
                        sx={{
                          fontSize: 8,
                        }}
                        as="h2">
                        {title}
                      </Heading>
                    </header>
                    <Stats />
                    <Box
                      sx={{
                        '& ul': {ml: 0, pl: 0},
                        '& li': {listStyle: 'none'},
                        '& .ais-InfiniteHits-loadMore': {
                          bg: 'orange',
                          color: 'white',
                          fontFamily: 'body',
                          border: 0,
                          fontSize: 3,
                          p: 3,
                          '&--disabled': {
                            display: 'none',
                          }
                        },
                        '& a': {
                          color: 'inherit',
                          textDecoration: 'none',
                        }
                      }}>
                      <InfiniteHits 
                      hitComponent={hitComps[hitComp]}
                      translations={{
                      loadPrevious: "Pokaži prethodno",
                        loadMore: 'Pokaži još',
                      }} />
                    </Box>
                  </Index>
                </Box>
              ))}
            </Box>
          </Box>
        )}
      </InstantSearch>
    </Box>
  );
};

const areEqual = (prevProps, nextProps) => {
  // console.log(prevProps, nextProps)
  if (prevProps === nextProps) {
    return false;
  }
  return true;
};

const SearchMemo = React.memo(Search);

export default SearchMemo;
