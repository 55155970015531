import React, {useEffect, useContext, useRef} from 'react';
import {Box, Heading, Button, Text, Input} from 'theme-ui';

import Image from "next/image"

import {useForm} from 'react-hook-form';
import AuthContext from '@/components/Contexts/AuthContext';
import loginGif from './login.gif';

const WarningLove = ({children}) => (
  <Text
    as="p"
    sx={{
      fontSize: 1,
      position: 'absolute',
      left: 3,
      bottom: [2, 2, 2, '5px'],
      color: 'red',
      letterSpacing: '0.1px',
      fontFamily: 'body',
    }}>
    {children}
  </Text>
);

const BoxLove = ({children}) => (
  <Box
    sx={{
      position: 'relative',
      pb: [1, 1, 1, 4],
      width: '100%',
      '& input::placeholder': {
        color: 'lightGray',
      },
    }}>
    {children}
  </Box>
);

const LoginForm = props => {
  const {isLogin, toggleRegisterLogin, toggleForgotPassword} = props;
  const {registerCustomer, loginCustomer, hasErrors, setHasErrors} = useContext(
    AuthContext
  );

  const {handleSubmit, register, errors} = useForm({
    mode: 'onBlur',
    shouldUnregister: false,
  });

  const formRef = useRef(null);

  const onSubmit = (values, {setSubmitting}) => {
    // setIsLoading(true) - define me
    isLogin ? loginCustomer(values) : registerCustomer(values);
  };

  // console.log('LoginForm errors', errors)

  const handleAuthError = () => {
    if (hasErrors && isLogin) {
      return (
        <Text as="p" sx={{color: 'red', textAlign: 'center', mb: 3}}>
          Tvoja adresa ili lozinka nisu dobro uneti ili nepostoje.
        </Text>
      );
    }
    if (hasErrors && !isLogin) {
      return (
        <Text as="p" sx={{color: 'white', fontSize: 2, textAlign: 'center', mb: 2}}>
          Već postoji nalog sa unetom email adresom. Ako je tvoj,{' '}
          <a onClick={toggleForgotPassword}>
            klikni ovde da resetujemo lozinku.
          </a>
        </Text>
      );
    }
  };

  return <>
    <form
      id="loginRegisterForm"
      onSubmit={handleSubmit(onSubmit)}
      ref={formRef}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'left',
          width: '300px',
        }}>
        <Box
          mb="3"
          sx={{
            height: 300,
            overflow: 'hidden',
            position: 'relative',
            display: ['none', 'none', 'block'],
          }}>
          <Image
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              backgroundSize: 'cover',
            }}
            src={loginGif}
          />
        </Box>

        <Heading
          sx={{
            fontSize: 7,
            textAlign: 'left',
            color: 'white',
            mb: 5,
          }}>
          {isLogin ? 'Log in' : 'Novi nalog'}
        </Heading>

        <BoxLove>
          <Input
            placeholder="Tvoj email"
            id="email"
            {...register('email', {
              required: 'Email je.. ipak potreban :)',
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: 'Mmm, izgleda da adresa nije dobro upisana...',
              },
            })}
            type="text"
            sx={{
              bg:
                errors?.email || hasErrors
                  ? '#ffb8b8'
                  : 'rgba(255,255,255,0.1)',
              color: 'white',
              mb: [
                errors?.email ? 4 : 0,
                errors?.email ? 4 : 0,
                errors?.email ? 4 : 0,
                errors?.email ? 0 : 0,
              ],
            }} />
          <WarningLove>
            {errors?.email ? errors?.email.message : null}
          </WarningLove>
        </BoxLove>

        <BoxLove>
          <Input
            placeholder="Lozinka"
            id="password"
            {...register('password', {
              required: 'Lozinka vrata otvara.. :)',
              minLength: {value: 6, message: '6 karaktera je minimum..'},
            })}
            type="password"
            sx={{
              color: 'white',
              bg:
                errors?.email || hasErrors
                  ? '#ffb8b8'
                  : 'rgba(255,255,255,0.1)',
              mb: [
                errors?.password ? 4 : 0,
                errors?.password ? 4 : 0,
                errors?.password ? 4 : 0,
                errors?.password ? 0 : 0,
              ],
              mb: isLogin ? 0 : 3,
            }} />
          <WarningLove>
            {errors?.password ? errors?.password.message : null}
          </WarningLove>
        </BoxLove>

        {handleAuthError()}

        <Button sx={{px: 4, py: 4, width: '100%', mb: 3}} type="submit">
          {isLogin ? 'Prijavi me' : 'Napravi nalog'}
        </Button>

        {isLogin && (
          <Box
            as="span"
            onClick={toggleForgotPassword}
            sx={{
              color: 'gray',
              cursor: 'pointer',
              fontFamily: 'body',
              fontWeight: 'normal',
              mb: 3,
              fontSize: 3,
            }}>
            Zaboravljena lozinka?{' '}
            <Text
              as="span"
              sx={{color: 'white', textDecoration: 'underline'}}>
              Kliknite ovde
            </Text>
          </Box>
        )}

        <Heading
          onClick={() => {
            toggleRegisterLogin(), setHasErrors(null);
          }}
          sx={{
            mt: 4,
            fontSize: 2,
            color: 'white',
            textAlign: 'left',
            width: '100%',
            '&:hover': {
              cursor: 'pointer',
              opacity: 0.75,
            },
          }}>
          {isLogin
            ? `Nemaš nalog? Registruj se ovde!`
            : 'Već imaš nalog? Prijavi se ovde'}
          <Button
            sx={{
              px: 4,
              py: 3,
              width: '100%',
              bg: 'rgba(255,255,255,0.0)',
              fontSize: 3,
              mt: 1,
              border: '1px solid orange',
            }}
            type="submit">
            {isLogin ? 'Napravi nalog' : 'Prijavi me'}
          </Button>
        </Heading>
      </Box>
    </form>
  </>;
};

export default LoginForm;
