import {Box} from 'theme-ui';

const IconWrapper = ({icon, noBorder, borderColor}) => {
  const handleSVG = requestedIcon => {
    switch (requestedIcon) {
      case 'user':
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <g opacity={noBorder ? '1' : '0.8'}>
              <path
                d="M9.99984 9.79167C12.5311 9.79167 14.5832 7.73964 14.5832 5.20833C14.5832 2.67703 12.5311 0.625 9.99984 0.625C7.46853 0.625 5.4165 2.67703 5.4165 5.20833C5.4165 7.73964 7.46853 9.79167 9.99984 9.79167Z"
                fill="#143131"
              />
              <path
                d="M9.99992 11.041C5.62976 11.0461 2.0883 14.5875 2.08325 18.9577C2.08325 19.1878 2.2698 19.3743 2.49992 19.3743H17.4999C17.73 19.3743 17.9166 19.1878 17.9166 18.9577C17.9115 14.5875 14.3701 11.0461 9.99992 11.041Z"
                fill="#143131"
              />
            </g>
          </svg>
        );
      case 'menu':
        return (
          <svg
            width="19"
            height="14"
            viewBox="0 0 19 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <rect
              x="0.000244141"
              width="18.3333"
              height="2"
              rx="1"
              fill="#174949"
            />
            <rect
              x="0.000244141"
              y="6"
              width="18.3333"
              height="2"
              rx="1"
              fill="#174949"
            />
            <rect
              x="0.000244141"
              y="12"
              width="18.3333"
              height="2"
              rx="1"
              fill="#174949"
            />
          </svg>
        );
      case 'basket':
        return (
          <svg
            width="26"
            height="26"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              opacity={noBorder ? '1' : '0.8'}
              fillRule="evenodd"
              clipRule="evenodd"
              d="M21.1287 20.8252C20.4679 21.5722 19.5177 22 18.5193 22H3.48255C2.48388 22.0005 1.53309 21.5729 0.871959 20.8259C0.210827 20.0789 -0.0969525 19.0845 0.0268832 18.0956L1.50086 6.39621C1.50554 6.35631 1.51393 6.31692 1.52592 6.27857V6.2656C1.53149 6.24893 1.53706 6.23318 1.54355 6.21836V6.21095L2.6973 3.49684C2.95503 2.93229 3.51272 2.56377 4.13414 2.54737H5.06234C5.44681 2.54737 5.75848 2.85841 5.75848 3.24211C5.75848 3.6258 5.44681 3.93684 5.06234 3.93684H4.13414C4.0538 3.95222 3.98712 4.00792 3.95779 4.08413L3.37024 5.46804C3.34004 5.53963 3.34782 5.62157 3.39095 5.68622C3.43408 5.75088 3.50682 5.78966 3.58465 5.78947H6.22258C6.35074 5.78947 6.45463 5.68579 6.45463 5.55789V4.63158C6.45463 2.07363 8.53247 0 11.0956 0C13.6587 0 15.7366 2.07363 15.7366 4.63158V5.55789C15.7366 5.68579 15.8405 5.78947 15.9686 5.78947H18.5035C18.5789 5.78921 18.6495 5.75257 18.693 5.69114C18.7365 5.62971 18.7475 5.55107 18.7226 5.48008L18.2149 4.05356C18.1835 3.99131 18.1241 3.94789 18.0552 3.93684H17.127C16.7425 3.93684 16.4309 3.6258 16.4309 3.24211C16.4309 2.85841 16.7425 2.54737 17.127 2.54737H18.0552C18.66 2.55769 19.2076 2.9065 19.4716 3.4496C19.4862 3.47682 19.4989 3.50498 19.5097 3.53389L20.4797 6.25171L20.4843 6.26745V6.27672C20.4961 6.31422 20.5048 6.35264 20.5103 6.39158V6.39806L21.9731 18.0956C22.0969 19.0842 21.7894 20.0783 21.1287 20.8252ZM11.1004 1.125C9.44355 1.125 8.1004 2.46815 8.1004 4.125V5.125C8.1004 5.26307 8.21233 5.375 8.3504 5.375H13.8504C13.9885 5.375 14.1004 5.26307 14.1004 5.125V4.125C14.1004 2.46815 12.7573 1.125 11.1004 1.125Z"
              fill="#143131"
            />
          </svg>
        );
      case 'close':
        return (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M1 1L8 8M15 15L8 8M8 8L15 1M8 8L1 15"
              stroke="#1F1F1F"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      case 'search':
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="18"
            fill="none"
            opacity={noBorder ? '1' : '0.8'}
            viewBox="0 0 24 23">
            <path
              fill="#F2994A"
              fillRule="evenodd"
              d="M24 8a8 8 0 01-12.602 6.545c-.085.218-.222.42-.41.584l-8 7a1.5 1.5 0 01-1.976-2.258l8-7c.135-.118.285-.207.443-.269A8 8 0 1124 8zm-3 0a5 5 0 11-10 0 5 5 0 0110 0z"
              clipRule="evenodd"
            />
          </svg>
        );
      case 'categoryList':
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="18"
            fill="none"
            viewBox="0 0 21 18">
            <rect width="15" height="3" x="6" fill="#F2994A" rx="1.5" />
            <rect width="15" height="3" x="6" y="10" fill="#F2994A" rx="1.5" />
            <rect width="15" height="3" x="6" y="5" fill="#F2994A" rx="1.5" />
            <rect width="15" height="3" x="6" y="15" fill="#F2994A" rx="1.5" />
            <g>
              <rect width="3" height="3" fill="#F2994A" rx="1.5" />
              <rect width="3" height="3" y="10" fill="#F2994A" rx="1.5" />
              <rect width="3" height="3" y="5" fill="#F2994A" rx="1.5" />
              <rect width="3" height="3" y="15" fill="#F2994A" rx="1.5" />
            </g>
          </svg>
        );
      default:
        return <div>missing</div>;
    }
  };

  return (
    <Box
      sx={{
        width: noBorder ? 32 : 42,
        height: noBorder ? 32 : 42,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderStyle: noBorder ? 'none' : 'solid',
        borderWidth: noBorder ? 0 : '1px',
        borderColor: borderColor ? borderColor : 'altGreen',
        borderRadius: '10px',
      }}>
      {handleSVG(icon)}
    </Box>
  );
};

export default IconWrapper;
