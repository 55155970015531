import React, {useContext, useRef, useEffect, useState} from 'react';
import {Box, Text} from 'theme-ui';
import {useTransition, animated} from 'react-spring';
import AuthContext from '@/components/Contexts/AuthContext';
import LoginForm from './LoginForm';
import ForgotPasswordForm from './ForgotPasswordForm';

import {toggleBodyScroll} from '@/components/Hooks/toggleBodyScroll';

const RegisterLogin = () => {
  // console.log('RegisterLogin rerendered')
  const {isCustomerPanelOpen, setIsCustomerPanelOpen, setHasErrors} = useContext(AuthContext);
  const [isLogin, setIsLogin] = useState(true);
  const [isForgotPassword, setIsForgotPassword] = useState(false);

  const toggleRegisterLogin = () => setIsLogin(!isLogin);
  const toggleForgotPassword = () => setIsForgotPassword(!isForgotPassword);

  const panelSpring = useTransition(isCustomerPanelOpen, {
    from: {
      position: 'fixed',
      opacity: 0,
      transform: 'translate3d(400px,0,0)',
    },
    enter: {
      opacity: 1,
      transform: 'translate3d(0,0,0)',
    },
    leave: {
      opacity: 0,
      transform: 'translate3d(400px,0,0)',
    },
  });

  const AnimatedBox = animated(Box);
  const AnimatedBg = animated(Box);
  const customerPanel = useRef(null);

  const handleClick = e => {
    if (
      customerPanel &&
      customerPanel.current &&
      customerPanel.current.contains(e.target)
    ) {
      return;
    }
    // if (typeof document !== `undefined`) {
    //   console.log('+_+', document.body);
    //   if (isCustomerPanelOpen) document.body.classList.add('disable-scroll');

    //   if (!isCustomerPanelOpen)
    //     document.body.classList.remove('disable-scroll');
    // }
    setHasErrors(null)
    setIsCustomerPanelOpen(!isCustomerPanelOpen);
  };

  useEffect(() => {
    if (isCustomerPanelOpen === true) {
      window.addEventListener('mousedown', handleClick, false);
    }
    isCustomerPanelOpen
      ? window.addEventListener('mousedown', handleClick, false)
      : window.removeEventListener('mousedown', handleClick, false);
    // if (typeof document !== `undefined`) {
    // if (isCustomerPanelOpen) document.body.classList.add('disable-scroll');
    // if (!isCustomerPanelOpen) document.body.classList.remove('disable-scroll');
    // }
    toggleBodyScroll(isCustomerPanelOpen);
    return () => {
      window.removeEventListener('mousedown', handleClick, false);
    };
  }, [isCustomerPanelOpen]);

  return (
    <>
      <AnimatedBg
        // ref={springBg}
        sx={{
          display: `${isCustomerPanelOpen ? 'block' : `none`}`,
          position: 'fixed',
          width: '100vw',
          height: '100vh',
          bg: 'black',
          opacity: '0.3',
          top: 0,
          left: 0,
          zIndex: 101,
          cursor: 'pointer',
        }}
      />
      {panelSpring((styles, item ) =>
        item ? (
          <AnimatedBox
            ref={customerPanel}
            style={styles}
            sx={{
              display: 'flex',
              position: 'fixed',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              px: '4',
              top: 0,
              py: '4',
              height: '100vh',
              width: ['100vw', '400px'],
              right: '0',
              // transform: `translateX(${isCustomerPanelOpen ? '0px' : '400px'})`,
              bg: 'primaryGreen',
              zIndex: 101,
            }}>
            <Box
              sx={{
                display: isForgotPassword ? 'none' : 'block',
              }}>
              <LoginForm
                isLogin={isLogin}
                toggleRegisterLogin={toggleRegisterLogin}
                toggleForgotPassword={toggleForgotPassword}
              />
            </Box>
            <Box
              sx={{
                display: isForgotPassword ? 'block' : 'none',
              }}>
              <ForgotPasswordForm
                toggleForgotPassword={toggleForgotPassword}
                toggleRegisterLogin={toggleRegisterLogin}
                isLogin={isLogin}
              />
            </Box>

            <Box
              sx={{
                width: 36,
                height: 36,
                position: 'absolute',
                top: 5,
                right: 5,
                border: '1px solid rgba(255,255,255,0.3)',
                borderRadius: 10,
                p: 2,
                '&:hover': {
                  cursor: 'pointer',
                },
              }}
              onClick={() => {
                setIsCustomerPanelOpen(false);
              }}>
              <Box
                sx={{
                  width: 2,
                  height: 16,
                  bg: 'white',
                  position: 'absolute',
                  left: '16px',
                  top: '9px',
                  transform: 'rotate(45deg)',
                }}
              />
              <Box
                sx={{
                  width: 2,
                  height: 16,
                  bg: 'white',
                  position: 'absolute',
                  left: '16px',
                  top: '9px',
                  transform: 'rotate(-45deg)',
                }}
              />
            </Box>
          </AnimatedBox>
        ) : null
      )}
    </>
  );
};

export default RegisterLogin;
