import React, {useState, useEffect, createRef, useRef} from 'react';
import {connectAutoComplete, Stats} from 'react-instantsearch-dom';
import {Input, Box, Text, Button} from 'theme-ui';

// import {Link, navigate} from 'gatsby';
import Link from 'next/link';
import { useRouter } from 'next/router';

import {useForm} from 'react-hook-form';

const useClickOutside = (ref, handler, events) => {
  if (!events) events = [`mousedown`, `touchstart`];
  const detectClickOutside = event =>
    !ref.current.contains(event.target) && handler();
  useEffect(() => {
    for (const event of events)
      document.addEventListener(event, detectClickOutside);
    return () => {
      for (const event of events)
        document.removeEventListener(event, detectClickOutside);
    };
  });
};

export default connectAutoComplete(
  ({hits, currentRefinement, refine, ...rest}) => {
    const ref = useRef();
    const [query, setQuery] = useState(``);
    const [focus, setFocus] = useState(false);

    const {register, handleSubmit, watch, errors} = useForm();
    const onSubmit = data => navigate(`/pretraga/?q=${query}`);

    useClickOutside(ref, () => setFocus(false));

    const hitsLength = hits.length;
    const shortList = hits.slice(0, 6);

    return (
      <Box
        sx={{
          height: '100%',
          width: '100%',
        }}
        ref={ref}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          style={{height: '100%', marginBottom: '0px'}}>
          <Input
            sx={{
              height: '100%',
              width: '100%',
              mb: 0,
            }}
            type="text"
            placeholder="Pretraži proizvode, savete, recepte..."
            aria-label="Pretraži sajt"
            value={currentRefinement}
            onChange={event => {
              setQuery(event.currentTarget.value);
              refine(event.currentTarget.value);
              setFocus(true);
            }}
            onFocus={e => setFocus(true)}
            {...rest} />
        </form>

        {query.length > 0 && focus && (
          <Box
            sx={{
              boxShadow: '0 1px 3px -2px',
              zIndex: 201,
              position: 'relative',
              height: ['300px', '300px', '300px', 'auto'],
              maxHeight: ['300px', '300px', '300px', '70vh'],
              overflow: 'auto',
              bg: 'white',
              width: ['100%', '100%', '100%', '100%', '100%', '50%'],
            }}>
            <Box
              sx={{
                px: 3,
                py: 2,
                fontSize: 2,
                bg: 'green',
                color: 'white',
                display: ['none', 'none', 'none', 'block'],
              }}>
              <Stats
                translations={{
                  stats(nbHits, timeSpentMS) {
                    return `Prikazano 6 od ${nbHits} rezultata za ${timeSpentMS}ms`;
                  },
                }}
              />
            </Box>
            {query.length > 0 &&
              focus &&
              shortList.map(x => (
                <Link href={`/${x.slug}` || "/"} passHref>
                  <Box as="a">
                  <Box
                    onClick={() => setFocus(false)}
                    sx={{
                      display: 'flex',
                      bg: 'white',
                      alignItems: 'center',
                      py: [1, 1, 1, 4],
                      px: [1, 1, 1, 4],
                      borderBottom: '1px solid rgba(0,0,0,0.1)',
                      transition: '0.5s all ease-in-out',
                      '&:hover': {
                        bg: '#eee',
                        cursor: 'pointer',
                      },
                    }}>
                    <Box
                      flexBasis={50}
                      sx={{
                        display: ['none', 'none', 'flex'],
                        alignItems: 'center',
                        justifyContent: 'center',
                        maxHeight: '50px',
                        maxWidth: '50px',
                        mr: 4,
                        p: 2,
                        bg: 'white',
                      }}>
                      {x.thumb && (
                        <img
                          style={{
                            width: 'auto',
                            maxWidth: '100%',
                            maxHeight: '50px',
                            marginBottom: '0px',
                          }}
                          src={x && x.thumb}
                        />
                      )}
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        fontFamily: 'body',
                      }}>
                      <Text sx={{fontWeight: 700, fontSize: 3}}>{x.name}</Text>
                      <Text sx={{fontSize: 3, color: 'gray'}}>
                        {x.cena} rsd
                      </Text>
                    </Box>
                  </Box>
                  </Box>
                </Link>
              ))}
            <Link
              onClick={() => setFocus(false)}
              href={`/pretraga/?q=${query}` || "/"}
              passHref>
                <Box as="a">
              <Text
                sx={{
                  fontFamily: 'body',
                  textAlign: 'center',
                  fontSize: 3,
                  py: 3,
                  bg: 'orange',
                  color: 'white',
                }}>
                Pogledaj sve rezultate
              </Text>
              </Box>
            </Link>
          </Box>
        )}
      </Box>
    );
  }
);
